import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Radio } from "antd";
import Phone from "./Phone";
import TabPhone from "./Tab";
import Laptop from "./Laptop";
import Watch from "./Watch";
import LedTv from "./Tvs";
import Fridge from "./Fridge";

const Addproducts = () => {

 
  const getCategoryName = (value: string) => {
    switch (value) {
      case "Smart-Phone":
        return "Phone";
      case "Tab":
        return "Tablet";
      case "Smart-Watch":
        return "Smart Watch";
      default:
        return "";
    }
  };
  // file upload
  const [selectedValue, setSelectedValue] = useState("Smart-Phone");
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const categoryName = getCategoryName(selectedValue);

  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <div className="d-flex justify-content-between">
          <div className="head-txt d-flex">
            <div className="icons-div">
              <i className="fi fi-rr-caret-left "></i>
            </div>
            <div>New Products</div>
          </div>
        </div>

        <div className="pt-3">
          <div>
            <label className="addbrand-label">
              Categories
              <span className="label-2">
                ( Input may differ according to categories)*
              </span>
            </label>
            <br />
            <div className="newbrand-checkbox">
              <Radio.Group onChange={handleChange} value={selectedValue} >
                <Radio value="Smart-Phone" className="radios">
                  Smart Phone
                </Radio>
                <Radio value="Tab">Tab</Radio>
                {/* <Radio value="c">Laptop</Radio> */}
                <Radio value="Smart-Watch">Smart Watch</Radio>
                {/* <Radio value="e">TV</Radio>
                <Radio value="f">Refridgerator</Radio> */}
              </Radio.Group>
            </div>

            {/* Conditionally Render the ProductDetailsForm Component */}
            {selectedValue === "Smart-Phone" && <Phone/>}
            {selectedValue === "Tab" && <TabPhone />}
            {/* {selectedValue === "c" && <Laptop />} */}
            {selectedValue === "Smart-Watch" && <Watch />}
            {/* {selectedValue === "e" && <LedTv />} */}
            {/* {selectedValue === "f" && <Fridge/>} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Addproducts;
