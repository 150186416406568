import axios from "axios";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message } from "antd"; // Import Ant Design message
import { API_URL } from "../Api/Config";

const SideBar = () => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDropdown = (dropdownName: string) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const handleNavOrder = () => {
    setOpenDropdown(null);
    navigate("/order");
  };

  const handleNavTransaction = () => {
    setOpenDropdown(null);
    navigate("/transaction");
  };

  const successMessage = (content: string) => {
    message.success(content);
  };

  const errorMessage = (content: string) => {
    message.error(content);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/user/auth/admin/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("dashtoken");
        successMessage("Logout successful");
        window.location.reload();
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        errorMessage(error.response?.data?.message || "An error occurred during logout.");
      } else {
        errorMessage("An unexpected error occurred.");
      }
    }
  };

  return (
    <section className="sidebar-container">
      <div>
        <div className="logo">
          <img src="/assets/logo.png" alt="img" className="img-fluid" />
        </div>

        <div className="pt-1 side-bar-contain">
          {/* Home Menu */}
          <div
            className={`side-icon-combine ${location.pathname === "/" ? "active" : ""}`}
          >
            <div className="icon-side ">
              <i className="fi fi-rs-house-chimney"></i>
            </div>
            <div className="side-content-1"><Link  to="/" className="link">Home</Link></div>
          </div>

          {/* Products Menu */}
          <div className="side-total">
            <div
              className={`side-icon-combine ${location.pathname === "/products" ? "active" : ""}`}
              onClick={() => toggleDropdown("products")}
            >
              <div className="icon-side">
                <i className="fi fi-rs-box-open"></i>
              </div>
              <Link to="/products" style={{ textDecoration: "none" }}>
                <div className="side-content">Products</div>
              </Link>
              <div className="dropdown-icon ms-1">
                {openDropdown === "products" ? (
                  <i className="fi fi-rs-caret-up"></i>
                ) : (
                  <i className="fi fi-rs-caret-down"></i>
                )}
              </div>
            </div>
            {openDropdown === "products" && (
              <div className="home-dropdown-combine">
                <div className="home-dropdown-content">
                  <Link to="/productlist" className="file">Product listing</Link>
                  <Link to="/addproducts" className="file">Add Products</Link>
                </div>
              </div>
            )}
          </div>

          {/* Orders Menu */}
          <div
            className={`side-icon-combine ${location.pathname === "/order" ? "active" : ""}`}
            onClick={handleNavOrder}
          >
            <div className="icon-side ">
              <i className="fi fi-rs-shopping-cart"></i>
            </div>
            <div className="side-content-1">Orders</div>
          </div>

          {/* Banners Menu */}
          <div className="side-total">
            <div
              className={`side-icon-combine ${location.pathname === "/banners" ? "active" : ""}`}
              onClick={() => toggleDropdown("banners")}
            >
              <div className="icon-side">
                <i className="fi fi-rs-ad"></i>
              </div>
              <Link to="/banners" style={{ textDecoration: "none" }}>
                <div className="side-content">Banner</div>
              </Link>
              <div className="dropdown-icon ms-3">
                {openDropdown === "banners" ? (
                  <i className="fi fi-rs-caret-up"></i>
                ) : (
                  <i className="fi fi-rs-caret-down"></i>
                )}
              </div>  
            </div>

            {openDropdown === "banners" && (
              <div className="home-dropdown-combine">
                <div className="home-dropdown-content">
                  <Link to="/home-banner" className="file">Hero Banners</Link>
                  <Link to="/home-offer" className="file">Home Offers</Link>
                  <Link to="/offer-Banner" className="file">Offers Banner</Link>
                </div>
              </div>
            )}
          </div>

          {/* Transaction Menu */}
          {/* <div
            className={`side-icon-combine ${location.pathname === "/transaction" ? "active" : ""}`}
            onClick={handleNavTransaction}
          >
            <div className="icon-side">
              <i className="fi fi-rs-sack-dollar"></i>
            </div>
            <div className="side-content-1">Transaction</div>
          </div> */}

          {/* Settings Menu */}
          <div className="gaping-side">
            <div className="side-total">
              <div
                className={`side-icon-combine ${location.pathname === "/settings" ? "active" : ""}`}
                onClick={() => toggleDropdown("settings")}
              >
                <div className="icon-side">
                  <i className="fi fi-rs-settings"></i>
                </div>
                <Link to="/settings" style={{ textDecoration: "none" }}>
                  <div className="side-content">Settings</div>
                </Link>
                <div className="dropdown-icon">
                  {openDropdown === "settings" ? (
                    <i className="fi fi-rs-caret-up"></i>
                  ) : (
                    <i className="fi fi-rs-caret-down"></i>
                  )}
                </div>
              </div>

              {openDropdown === "settings" && (
                <div className="home-dropdown-combine">
                  <div className="home-dropdown-content">
                    <Link to="/profile" className="file">Profile</Link>
                    <Link to="/domain" className="file">Domain</Link>
                    <Link to="/subadmin" className="file">Subadmin</Link>
                    <Link to="/set-estimate-delivery" className="file">Set Estimate Delivery</Link>
                  </div>
                </div>
              )}
            </div>

            {/* Logout */}
            <div
              className={`side-icon-combine`}
              onClick={handleLogout} // Handle logout directly on click
            >
              <div className="icon-side">
                <i className="fi fi-rs-sign-out-alt"></i>
              </div>
              <div className="side-content-1">
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SideBar;
