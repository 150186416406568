import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Select, Input, Table } from "antd";
import { API_URL } from "../Api/Config";
import type { TableColumnsType, TableProps } from "antd";
import { GrPowerReset } from "react-icons/gr";
import { IoSearch } from "react-icons/io5";
import { Box, Modal, Paper, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Table as TableM } from "@mui/material";
import html2pdf from "html2pdf.js";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "90%",
  overflowY: "scroll",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};

interface Order {
  key: React.Key;
  Orderid: string;
  OrderedDate: string;
  Productname: string;
  specs: string;
  TotalPrize: string;
  mrpPrice: string;
  Shippingaddress: string;
  name: string;
  Payment: string;
  Refundstatus: string;
}

interface ShippingAddress {
  firstName: string;
  lastName: string;
  houseNumberAndFloor: string;
  locality: string;
  landmark: string;
  pincode: string;
  phoneNumber: string;
}

interface Product {
  _id: string;
  productPrice: number;
  discountedPrice: number;
  discountInPercent: number;
  quantity: number;
  storage: string;
  categories: string;
  colorName: string;
  variantId: string;
  ram: string;
}

interface OrderData {
  _id: string;
  shippingAddress: ShippingAddress;
  cancellationDate:string;
  products: [
    {
      _id: string;
      productPrice: number;
      discountedPrice: number;
      discountInPercent: number;
      quantity: number;
      storage: string;
      categories: string;
      colorName: string;
      variantId: string;
      ram: string;
    }
  ];
  paymentAmount: number;
  paymentMethod: string;
  paymentStatus: string;
  exchange: boolean;
  orderDate: string;
  deliveryType: string;
  deliveryDate: string;
}

const Cancelledorder = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [orderIdFilter, setOrderIdFilter] = useState('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [ogData, setOgData] = useState<OrderData[]>([]);
  const [viewDta, setViewDta] = useState<OrderData[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleViewOrders = (id: any) => {
    const filterdts = ogData.filter((val) => val._id === id);
    console.log(filterdts);
    setViewDta(filterdts);
    if (viewDta) {
      handleOpen();
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/orders/?isCancelled=true`);
        setOgData(response.data?.data?.orders)
        const fetchedOrders = response.data?.data?.orders.map((order: any) => ({
          key: order._id,
          Orderid: order._id,
          OrderedDate: new Date(order.orderDate).toLocaleDateString(),
          Productname: order.products[0]?.productName || "N/A", // Ensure correct product name
          specs: `Quantity: ${order.products[0]?.quantity}, RAM: ${order.products[0]?.ramSize}, Storage: ${order.products[0]?.storage}, Color: ${order.products[0]?.colorName}`,
          TotalPrize: order.finalAmount.toFixed(2),
          mrpPrice: order.totalAmount.toFixed(2),
          Shippingaddress: `${order.shippingAddress.houseNumberAndFloor}, ${order.shippingAddress.locality}, ${order.shippingAddress.pincode}`,
          name: order.cancellationReason || "N/A", // Display cancellation reason if available
          Payment: order.paymentMethod.toUpperCase(),
          Refundstatus: order.refundStatus,
        }));
        setOrders(fetchedOrders);
        setFilteredOrders(fetchedOrders);  // Initialize filtered orders with all fetched orders
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, []);

  const handleStatusChange = async (value: string, orderId: string) => {
    try {
      await axios.patch(`${API_URL}/api/orders/${orderId}/refund`, { refundStatus: value });
      setOrders((prevOrders) =>
        prevOrders.map((order) => (order.Orderid === orderId ? { ...order, Refundstatus: value } : order))
      );
    } catch (error) {
      console.error("Error updating refund status:", error);
    }
  };

  const handleApplyFilter = () => {
    let filtered = [...orders];

    if (orderIdFilter) {
      filtered = filtered.filter((order) =>
        order.Orderid.toLowerCase().includes(orderIdFilter.toLowerCase())
      );
    }

    if (paymentTypeFilter) {
      filtered = filtered.filter((order) => order.Payment.toLowerCase() === paymentTypeFilter.toLowerCase());
    }

    if (statusFilter) {
      filtered = filtered.filter((order) => order.Refundstatus.toLowerCase() === statusFilter.toLowerCase());
    }

    setFilteredOrders(filtered);
  };

  const handleReset = () => {
    setOrderIdFilter('');
    setPaymentTypeFilter('');
    setStatusFilter('');
    setFilteredOrders(orders); // Reset the filtered orders to all fetched orders
  };

  const columns: TableColumnsType<Order> = [
    { title: "Order ID", dataIndex: "Orderid", render: (text) => <p>{text.slice(-5)}</p> },
    { title: "Canceled Date", dataIndex: "OrderedDate", render: (text) => <p>{text}</p> },
    {
      title: "Product Name",
      dataIndex: "Productname",
      render: (text, record) => (
        <>
          <p>{text}</p>
          {/* <span>{record.specs}</span> */}
        </>
      ),
    },
    {
      title: "Total Prize",
      dataIndex: "TotalPrize",
      render: (text, record) => (
        <>
          <p>₹ {text}</p>
          {/* <span>MRP ₹ {record.mrpPrice}</span> */}
        </>
      ),
    },
    { title: "Reason", dataIndex: "name", render: (text) => <p>{text}</p> },
    { title: "Payment Type", dataIndex: "Payment", render: (text) => <p>{text.toUpperCase()}</p> },    {
      title: "View All",
      dataIndex: "Orderid",
      key: "Orderid",
      render: (Orderid:any) => (
        <span
          onClick={() => handleViewOrders(Orderid)}
          style={{
            color: "#444444",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          View
        </span>
      ),
    },
    {
      title: "Refund Status",
      dataIndex: "Refundstatus",
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handleStatusChange(value, record.Orderid)}
          style={{ width: "100%" }}
        >
          <Select.Option value="pending">Pending</Select.Option>
          <Select.Option value="processed">Processed</Select.Option>
          <Select.Option value="refunded">Refunded</Select.Option>
        </Select>
      ),
    },
  ];

  const rowSelection: TableProps<any>["rowSelection"] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows:", selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",  // Example condition
      name: record.name,
    }),
  };


  const pdfRef = useRef<HTMLDivElement | null>(null);

  const handleDownloadPdf = () => {
    const element = pdfRef.current;
    const options = {
      margin: 0.5,
      filename: `Order_${viewDta[0]?._id.slice(-5)}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  return (

    <>
   <div className="select-combine mt-5">
        <div>
          <label className="brand-label">Order ID</label><br />
          <Input
            className="select-brand"
            placeholder="Enter Order ID"
            value={orderIdFilter}
            onChange={(e) => setOrderIdFilter(e.target.value)}
          />
        </div>
        <div>
        <div>
          <label className="brand-label">Payment type</label>
          <br />
          <Select
          value={paymentTypeFilter}
          onChange={(value) => setPaymentTypeFilter(value)}
            className="select-brand"
            options={[
              { value: "cod", label: "COD" },
              { value: "Upi", label: "UPI" },
              { value: "Card", label: "Card" },
            ]}
            suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
          />
        </div>
        </div>
        <div>
          <label className="brand-label">Order status</label>
          <br />
          <Select
          value={statusFilter}
          onChange={(value) => setStatusFilter(value)}
            className="select-brand"
            options={[
              { value: "pending", label: "pending" },
              { value: "ordered", label: "ordered" },
              { value: "out for delivery", label: "out for delivery" },
            ]}
            suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
          />
        </div>

        
        <div style={{ marginTop: "28px" }}>
        <Button className="btn-1" onClick={handleApplyFilter}>
          <IoSearch />
          </Button>
          <Button className="btn-1"  style={{ marginLeft: "10px" }} onClick={handleReset}><GrPowerReset />
          </Button>
        </div>
      </div>
      
      <Table<Order>
                  rowSelection={{ ...rowSelection }}
        columns={columns}
        dataSource={filteredOrders} 
        pagination={false}
      />

<div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
            <div ref={pdfRef}>
              <div className="d-flex justify-content-between pb-3">
                <div>
                  <h3 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Order Details
                  </h3>

                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order ID:</strong>{" "}
                    {viewDta[0]?._id.slice(-5)}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order Date:</strong>{" "}
                    {new Date(viewDta[0]?.orderDate).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Amount:</strong>{" "}
                    ₹ {viewDta[0]?.paymentAmount}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Method:</strong>{" "}
                    {viewDta[0]?.paymentMethod}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Status:</strong>{" "}
                    {viewDta[0]?.paymentStatus}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Exchange Type:</strong>{" "}
                    {viewDta[0]?.exchange === true
                      ? "With Exchange"
                      : "Without exchange"}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Delivery Type:</strong>{" "}
                    {viewDta[0]?.deliveryType}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Cancellation Date:</strong>{" "}
                    {new Date(viewDta[0]?.cancellationDate).toLocaleDateString()}
                  </p>
                </div>

                <div>
                  <h3
                    className=""
                    style={{ fontSize: "18px", fontWeight: 600 }}
                  >
                    Shipping Address
                  </h3>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.firstName}{" "}
                    {viewDta[0]?.shippingAddress?.lastName}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.houseNumberAndFloor}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.locality}
                  </p>
                  <p className="mb-0">{viewDta[0]?.shippingAddress?.pincode}</p>
                </div>
              </div>
              <TableContainer component={Paper}>
                <TableM aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell>Product Price</TableCell>
                      <TableCell>DiscountedPrice Price</TableCell>
                      <TableCell>discountInPercent Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Storage</TableCell>
                      <TableCell>Categories</TableCell>
                      <TableCell>Color</TableCell>
                      {/* <TableCell align="right">variantId</TableCell> */}
                      <TableCell>Ram</TableCell>
                    </TableRow>
                  </TableHead>

                  {viewDta.length > 0 &&
                    viewDta[0].products?.map((row: any) => (
                      <>
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row._id.slice(-5)}
                          </TableCell>
                          <TableCell align="center">
                          ₹ {row.productPrice ? row.productPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                          ₹ {row.discountedPrice ? row.discountedPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.discountInPercent
                              ? row.discountInPercent
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.quantity ? row.quantity : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.storage ? row.storage : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.categories ? row.categories : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.colorName ? row.colorName : "-"}
                          </TableCell>
                          {/* <TableCell align="right">
      {row?.variantId.slice(-5)}
    </TableCell> */}
                          <TableCell align="center">
                            {row.ram ? row.ram : "-"}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableM>
              </TableContainer>
              </div>
              <div className="text-center py-3">
                <Button onClick={handleClose} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="me-1">Close</Button>
                <Button onClick={handleDownloadPdf} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="ms-1">Download</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Cancelledorder;
