import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { Switch } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom"; // Import useNavigate here
import axios from "axios";
import type { RcFile } from "antd/es/upload/interface";
import { API_URL } from "../../Api/Config";

const AddOfferBanner = () => {
  const [position, setPosition] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState<RcFile | null>(null);
  const [active, setActive] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const { bannerType } = useParams();
  const navigate = useNavigate();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file as RcFile);
        setImagePreview(reader.result as string);
        message.success(`${file.name} selected successfully`);
      };
      reader.readAsDataURL(file);
    }
  };

  const getApiUrl = () => {
    switch (bannerType) {
      case "offer":
        return `${API_URL}/api/banner/offerBanners`;
      case "festival":
        return `${API_URL}/api/banner/festivalBanners`;
      case "bestOfElectronics":
        return `${API_URL}/api/banner/bestOfElectronicsBanners`;
      case "hotDealsSmartPhone":
        return `${API_URL}/api/banner/hotDealsSmartPhoneBanners`;
      case "limitedTimeDeals":
        return `${API_URL}/api/banner/limitedTimeDealsBanners`;
      default:
        message.error("Invalid banner type.");
        return "";
    }
  };

  const getBannerSizeDescription = () => {
    switch (bannerType) {
      case "offer":
        return "(Upload in 1440*300 px)";
      case "festival":
        return "(Upload in 1400*300 px)";
      case "bestOfElectronics":
        return "(Upload in 560*300 px)";
      case "hotDealsSmartPhone":
        return "(Upload in 360*300 px)";
      case "limitedTimeDeals":
        return "(Upload in 560*300 px)";
      default:
        return "(Upload in appropriate dimensions)";
    }
  };

  const handleSubmit = async () => {
    if (!position || !image) {
      message.error("Please fill in all fields and upload an image.");
      return;
    }

    const formData = new FormData();
    formData.append("position", position);
    formData.append("link", link);
    formData.append("active", JSON.stringify(active));
    formData.append("image", image);
    formData.append("couponCode", couponCode);
    formData.append("discount", discount);

    const apiUrl = getApiUrl();
    if (!apiUrl) return;

    try {
      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Banner added successfully!");
      navigateAfterBannerAdded(bannerType);
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Failed to add banner. Please try again.");
    }
  };

  const navigateAfterBannerAdded = (bannerType: string | undefined) => {
    switch (bannerType) {
      case "offer":
        navigate("/Offerbannner");
        break;
      case "festival":
        navigate("/festival-banner");
        break;
      case "bestOfElectronics":
        navigate("/best-of-electronics");
        break;
      case "hotDealsSmartPhone":
        navigate("/hot-deal-of-smartphone");
        break;
      case "limitedTimeDeals":
        navigate("/limited-time-deal");
        break;
      default:
        message.error("Invalid banner type.");
        break;
    }
  };

  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <Container>
        <div className="d-flex justify-content-between mb-3">
          <div className="head-txt d-flex">
            <div className="icons-div">
              <Link to="/home-banner" className="links">
                <i className="fi fi-rr-caret-left"></i>
              </Link>
            </div>
            <h2 className="head-txt">Add Banners</h2>
          </div>
        </div>
        <div className="addbanner">
          <label className="addbrand-label mt-3">Position</label>
          <br />
          <Input value={position} onChange={(e) => setPosition(e.target.value)} />
          <br />
          <label className="addbrand-label mt-3">
            Banner <span>{getBannerSizeDescription()}</span>
          </label>
          <br />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ marginBottom: "10px" }}
          />
          {imagePreview && (
            <div style={{ marginTop: "10px" }}>
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "150px",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
          <br />
          <label className="addbrand-label mt-3">Link <span>(Destination)</span></label>
          <br />
          {(couponCode || discount) ? null : (
            <Input value={link} onChange={(e) => setLink(e.target.value)} />
          )}
          <br />

          <label className="addbrand-label mt-3">Coupon Code</label>
          <br />
          <Input value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
          <br />

          <label className="addbrand-label mt-3">Discount</label>
          <br />
          <Input type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} />
          <br />
          <p>
            Off <Switch checked={active} onChange={() => setActive(!active)} size="small" /> On
          </p>
        </div>
        <div className="button-settings pt-2">
          <Button className="btn-1" onClick={handleSubmit}>Save</Button>
          <Button className="btn-2">Cancel</Button>
        </div>
      </Container>
    </section>
  );
};

export default AddOfferBanner;


