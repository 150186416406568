import { Button, Input, Select } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import { Box, MenuItem, Modal, Paper, Select as SelectM, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import type { TableColumnsType, TableProps } from "antd";
import { API_URL } from "../Api/Config";
import { IoSearch } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { Table as TableM } from "@mui/material";
import html2pdf from "html2pdf.js";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "90%",
  overflowY: "scroll",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};



interface DataType {
  key: React.Key;
  Orderid: string;
  OrderedDate: string;
  Productname: string;
  specs: string;
  TotalPrize: string;
  mrpPrice: string;
  Shippingaddress: string;
  name: string;
  Payment: string;
  orderStatus: string;
}



const handleStatusChange = (value: string, orderId: string) => {
  // Implement logic to update order status based on the orderId and selected value
  console.log("Order ID:", orderId, "Status changed to:", value);
};

const rowSelection: TableProps<any>["rowSelection"] = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
  },
};

interface ShippingAddress {
  firstName: string;
  lastName: string;
  houseNumberAndFloor: string;
  locality: string;
  landmark: string;
  pincode: string;
  phoneNumber: string;
}

interface Product {
  _id: string;
  productPrice: number;
  discountedPrice: number;
  discountInPercent: number;
  quantity: number;
  storage: string;
  categories: string;
  colorName: string;
  variantId: string;
  ram: string;
}

interface OrderData {
  _id: string;
  shippingAddress: ShippingAddress;
  replacementDate:string;
  replacedOrderReason:string;
  products: [
    {
      _id: string;
      productPrice: number;
      discountedPrice: number;
      discountInPercent: number;
      quantity: number;
      storage: string;
      categories: string;
      colorName: string;
      variantId: string;
      ram: string;
    }
  ];
  paymentAmount: number;
  paymentMethod: string;
  paymentStatus: string;
  exchange: boolean;
  orderDate: string;
  deliveryType: string;
  deliveryDate: string;
}

const Replacementorder = () => {
  const [orderId, setOrderId] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("");
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [data, setData] = useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>([]); // For temporarily storing filtered data
  const [ogData, setOgData] = useState<OrderData[]>([]);
  const [viewDta, setViewDta] = useState<OrderData[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
console.log(viewDta);

  const handleViewOrders = (id: any) => {
    const filterdts = ogData.filter((val) => val._id === id);
    console.log(filterdts);
    setViewDta(filterdts);
    if (viewDta) {
      handleOpen();
    }
  };


  const columns: TableColumnsType<any> = [
    {
      title: "Order id",
      dataIndex: "Orderid",
      render: (text: string) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {text.slice(-5)}
        </p>
      ),
    },
    {
      title: "Replacement req date",
      dataIndex: "OrderedDate",
      render: (text: string) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {text}
        </p>
      ),
    },
    {
      title: "Product name",
      dataIndex: "Productname",
      render: (text: string, record: any) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>
            {text}
          </p>
          {/* <span style={{ fontSize: "12px", fontWeight: 400, color: "#6C6C6C" }}>
            ({record.specs})
          </span> */}
        </>
      ),
    },
 
    {
      title: "Pick-up address",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>
            {text}
          </p>
          <span style={{ fontSize: "12px", fontWeight: 400, color: "#6C6C6C" }}>
            {record.Shippingaddress}
          </span>
        </>
      ),
    },
    {
      title: "Reason",
      dataIndex: "name",
      render: (text: string) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {text}
        </p>
      ),
    },
    {
      title: "PickupDay",
      dataIndex: "Pickup",
      render: (text: string) => (
        <p className="mb-0" style={{ color: "#444444" }}>
        {text}
      </p>
      ),
    },
    {
      title: "View All",
      dataIndex: "Orderid",
      key: "Orderid",
      render: (Orderid:any) => (
        <span
          onClick={() => handleViewOrders(Orderid)}
          style={{
            color: "#444444",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          View
        </span>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      render: (status: string, record: any) => (
        <SelectM
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="statusDrop"
          value={status}
          onChange={(e) => handleStatusChange(e.target.value, record.Orderid)}
          sx={{
            border: "none",
            "& fieldset": {
              border: "none",
            },
            backgroundColor: "#fff",
            paddingRight: "27px",
            color: "#444444",
          }}
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="packed">Packed</MenuItem>
          <MenuItem value="shipped">Shipped</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
        </SelectM>
      ),
    },
  ];


  // Fetch data from API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/orders/?replacemenstatus=replaced`);
        const orders = response.data?.data?.orders;
        setOgData(orders)
        if (Array.isArray(orders)) {
          console.log("Fetched orders:", orders);
    
          const transformedData = orders.map((order) => ({
            key: order._id,
            Orderid: order._id,
            Pickup:order.PickupDate || "-",
            OrderedDate: order.orderDate
              ? new Date(order.orderDate).toLocaleDateString()
              : "N/A", // Handle missing orderDate
            Productname: order.products?.[0]?.productName || "Unknown Product", // Safely access nested properties
            specs: order.products?.[0]
              ? `${order.products[0].ramSize || "N/A"} RAM, ${order.products[0].storage || "N/A"} ROM`
              : "No specs available",
            TotalPrize: order.products?.[0]?.productPrice || "N/A",
            mrpPrice: order.products?.[0]?.productPrice || "N/A",
            Shippingaddress: order.shippingAddress
              ? `${order.shippingAddress.houseNumberAndFloor || "N/A"}`
              : "No address available",
            name: order.shippingAddress
              ? `${order.shippingAddress.firstName || "N/A"} ${order.shippingAddress.lastName || ""}`
              : "Unknown",
            Payment: order.paymentMethod || "Unknown",
            orderStatus: order.status || "Unknown",
          }));
    
          console.log("Transformed data:", transformedData); // Debug transformed data
          setData(transformedData); // Set the transformed data
          setFilteredData(transformedData); // Initially set filtered data as all fetched data
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
  }, []);

  const handleOrderIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderId(e.target.value);
  };

  const handleFilterChange = (type: string, value: string) => {
    if (type === "paymentType") {
      setPaymentType(value);
    } else if (type === "orderStatus") {
      setOrderStatus(value);
    }
  };

  const applyFilters = () => {
    const filtered = data.filter((order) => {
      const matchOrderId = orderId ? order.Orderid.includes(orderId) : true;
      const matchPaymentType = paymentType ? order.Payment.toLowerCase() === paymentType.toLowerCase() : true;
      const matchOrderStatus = orderStatus ? order.orderStatus.toLowerCase() === orderStatus.toLowerCase() : true;
      
      return matchOrderId && matchPaymentType && matchOrderStatus;
    });

    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setOrderId("");
    setPaymentType("");
    setOrderStatus("");
    setFilteredData(data); // Reset filtered data
  };

  const pdfRef = useRef<HTMLDivElement | null>(null);

  const handleDownloadPdf = () => {
    const element = pdfRef.current;
    const options = {
      margin: 0.5,
      filename: `Order_${viewDta[0]?._id.slice(-5)}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };


  return (
    <>
      {/* <div className="d-flex justify-content-end mt-3">
        <Dropdown>
          <Dropdown.Toggle className="btn-down">Download</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#">Order</Dropdown.Item>
            <Dropdown.Item href="#">Invoice</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}

      <div className="select-combine mt-5">
        <div>
          <label className="brand-label">Order id</label>
          <br />
          <Input
            className="select-brand"
            value={orderId}
            onChange={handleOrderIdChange}
          />
        </div>

        <div>
          <label className="brand-label">Payment Method</label>
          <br />
          <Select
            onChange={(value) => handleFilterChange("paymentType", value)}
            className="select-brand"
            options={[
              { value: "COD", label: "COD" },
              { value: "Card", label: "Card" },
              { value: "UPI", label: "UPI" },
            ]}
            value={paymentType}
          />
        </div>

        <div>
          <label className="brand-label">Order Status</label>
          <br />
          <Select
            onChange={(value) => handleFilterChange("orderStatus", value)}
            className="select-brand"
            options={[
              { value: "pending", label: "Pending" },
              { value: "packed", label: "Packed" },
              { value: "shipped", label: "Shipped" },
              { value: "completed", label: "Completed" },
            ]}
            value={orderStatus}
          />
        </div>

        <div style={{ marginTop: "28px" }}>
        <Button className="btn-1" onClick={applyFilters}>
          <IoSearch />
          </Button>
          <Button className="btn-1"  style={{ marginLeft: "10px" }} onClick={resetFilters}><GrPowerReset />
          </Button>
        </div>
      </div>

      <div className="pt-3">
        <Table
          className="custom-table"
          rowSelection={rowSelection}
          columns={columns}
          pagination={false}
          dataSource={filteredData} // Use filtered data
        />
      </div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
            <div ref={pdfRef}>
              <div className="d-flex justify-content-between pb-3">
                <div>
                  <h3 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Order Details
                  </h3>

                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order ID:</strong>{" "}
                    {viewDta[0]?._id.slice(-5)}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order Date:</strong>{" "}
                    {new Date(viewDta[0]?.orderDate).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Amount:</strong>{" "}
                    ₹ {viewDta[0]?.paymentAmount}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Method:</strong>{" "}
                    {viewDta[0]?.paymentMethod}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Status:</strong>{" "}
                    {viewDta[0]?.paymentStatus}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Exchange Type:</strong>{" "}
                    {viewDta[0]?.exchange === true
                      ? "With Exchange"
                      : "Without exchange"}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Delivery Type:</strong>{" "}
                    {viewDta[0]?.deliveryType}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Replacement Date:</strong>{" "}
                    {new Date(viewDta[0]?.replacementDate).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Reason:</strong>{" "}
                    {viewDta[0]?.replacedOrderReason}
                  </p>
                </div>

                <div>
                  <h3
                    className=""
                    style={{ fontSize: "18px", fontWeight: 600 }}
                  >
                    Shipping Address
                  </h3>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.firstName}{" "}
                    {viewDta[0]?.shippingAddress?.lastName}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.houseNumberAndFloor}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.locality}
                  </p>
                  <p className="mb-0">{viewDta[0]?.shippingAddress?.pincode}</p>
                </div>
              </div>
              <TableContainer component={Paper}>
                <TableM aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell>Product Price</TableCell>
                      <TableCell>DiscountedPrice Price</TableCell>
                      <TableCell>discountInPercent Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Storage</TableCell>
                      <TableCell>Categories</TableCell>
                      <TableCell>Color</TableCell>
                      {/* <TableCell align="right">variantId</TableCell> */}
                      <TableCell>Ram</TableCell>
                    </TableRow>
                  </TableHead>

                  {viewDta.length > 0 &&
                    viewDta[0].products?.map((row: any) => (
                      <>
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row._id.slice(-5)}
                          </TableCell>
                          <TableCell align="center">
                          ₹ {row.productPrice ? row.productPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                          ₹ {row.discountedPrice ? row.discountedPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.discountInPercent
                              ? row.discountInPercent
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.quantity ? row.quantity : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.storage ? row.storage : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.categories ? row.categories : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.colorName ? row.colorName : "-"}
                          </TableCell>
                          {/* <TableCell align="right">
      {row?.variantId.slice(-5)}
    </TableCell> */}
                          <TableCell align="center">
                            {row.ram ? row.ram : "-"}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableM>
              </TableContainer>
              </div>
              <div className="text-center py-3">
                <Button onClick={handleClose} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="me-1">Close</Button>
                <Button onClick={handleDownloadPdf} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="ms-1">Download</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Replacementorder;
