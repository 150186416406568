import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { message } from "antd"; // Import Ant Design message
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Api/Config";

const style = {
  top: "35%",
  left: "89%",
  transform: "translate(-50%, -50%)",
  width: 320,
  height: "400px",
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "5px",
  boxShadow: 24,
  position: "relative", // For positioning the triangle edges
};

const triangleStyle = {
  width: "10px",
  height: "10px",
  borderStyle: "solid",
  borderWidth: "10px",
  borderColor: "transparent transparent white transparent",
  position: "absolute" as const,
};

interface User {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  profileImage: string;
}

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userData, setUserData] = useState<User | null>(null);
  const navigate = useNavigate();

  const handleOpenNotification = () => setOpen(true);
  const handleCloseNotification = () => setOpen(false);

  const handleOpenUserModal = () => setUserModalOpen(true);
  const handleCloseUserModal = () => setUserModalOpen(false);

  const successMessage = (content: string) => {
    message.success(content);
  };

  const errorMessage = (content: string) => {
    message.error(content);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/user/getMe`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
          },
        });
        if (response.status === 200) {
          setUserData(response.data.user); // Assuming response.data.user contains user details
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          errorMessage(error.response?.data?.message || "Failed to fetch user data.");
        } else {
          errorMessage("An unexpected error occurred.");
        }
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/user/auth/admin/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dashtoken")}`,
          },
        }
      );
      if (response.status === 200) {
        localStorage.removeItem("dashtoken");
        successMessage("Logout successful");
        navigate("/login"); // Redirect to login
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorMessage(error.response?.data?.message || "An error occurred during logout.");
      } else {
        errorMessage("An unexpected error occurred.");
      }
    }
  };

  return (
    <section className="header-container">
      <div className="header-combine">
        {/* Notification Bell */}
        <div className="notify" onClick={handleOpenNotification}>
          <i className="fi fi-rs-bell-notification-social-media"></i>
        </div>

        {/* User Profile */}
        <div className="login-header" onClick={handleOpenUserModal}>
          <div className="profile-img">
            <img
              src={userData?.profileImage || "assets/profile.png"}
              className="img-fluid"
              alt="profile"
            />
          </div>
          <div className="profile-content">
            <div>
              {userData ? `${userData.firstName} ${userData.lastName}` : "Loading..."}
            </div>
            <div className="mt-1">
              <i className="fi fi-rs-caret-down"></i>
            </div>
          </div>
        </div>
      </div>

      {/* Notification Modal */}
      <Modal
        open={open}
        onClose={handleCloseNotification}
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <div className="notify-img mt-5">
              <img
                src="assets/notification-img.png"
                className="img-fluid"
                alt="notification-img"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="notify-content">
              <h5>No Notification!</h5>
              <p>There is no notification to show right now. You’re up-to-date</p>
            </div>
            <div
              style={{
                ...triangleStyle,
                top: "-20px",
                left: "23%",
              }}
            />
          </div>
        </Box>
      </Modal>

      {/* User Modal */}
      <Modal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        aria-labelledby="user-modal-title"
        aria-describedby="user-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <div className="notify-img mt-5">
              <img
                src={userData?.profileImage || "assets/userlogo.png"}
                className="img-fluid"
                alt="user-logo"
              />
            </div>
            <div className="notify-content">
              <h5 className="mb-0">
                {userData ? `${userData.firstName} ${userData.lastName}` : "Loading..."}
              </h5>
              <p className="user-txt">{userData?.role || "Loading..."}</p>
              <p className="user-txt" style={{ color: "#202224" }}>
                {userData?.email || "Loading..."}
              </p>
              <p className="d-flex justify-content-center user-txt-1">
                Logout &nbsp;
                <i
                  onClick={handleLogout}
                  className="fi fi-rr-sign-out-alt user-txt-1"
                  style={{ cursor: "pointer" }}
                ></i>
              </p>
            </div>
            <div
              style={{
                ...triangleStyle,
                top: "-20px",
                left: "67%",
              }}
            />
          </div>
        </Box>
      </Modal>
    </section>
  );
};

export default Header;
