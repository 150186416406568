import { Button, Input, message, Select, Table } from "antd";
import React, { useState, useEffect, useRef } from "react";
import type { TableColumnsType, TableProps } from "antd";
import { Table as TableM } from "@mui/material";
import html2pdf from "html2pdf.js";
import {
  Box,
  MenuItem,
  Modal,
  Paper,
  Select as SelectM,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dropdown } from "react-bootstrap";
import axios from "axios"; // Assuming you're using axios for API calls
import { API_URL } from "../Api/Config";
import { IoSearch } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";

interface DataType {
  key: React.Key;
  Orderid: string;
  OrderedDate: string;
  Productname: string;
  specs: string;
  TotalPrize: string;
  mrpPrice: string;
  Shippingaddress: string;
  name: string;
  Payment: string;
  orderStatus: string;
}

interface ShippingAddress {
  firstName: string;
  lastName: string;
  houseNumberAndFloor: string;
  locality: string;
  landmark: string;
  pincode: string;
  phoneNumber: string;
}

interface Product {
  _id: string;
  productPrice: number;
  discountedPrice: number;
  discountInPercent: number;
  quantity: number;
  storage: string;
  categories: string;
  colorName: string;
  variantId: string;
  ram: string;
}

interface OrderData {
  _id: string;
  shippingAddress: ShippingAddress;
  products: [
    {
      _id: string;
      productPrice: number;
      discountedPrice: number;
      discountInPercent: number;
      quantity: number;
      storage: string;
      categories: string;
      colorName: string;
      variantId: string;
      ram: string;
    }
  ];
  paymentAmount: number;
  paymentMethod: string;
  paymentStatus: string;
  exchange: boolean;
  orderDate: string;
  deliveryType: string;
  deliveryDate: string;
}

const updateOrderStatus = async (orderId: string, newStatus: string) => {
  try {
    const response = await axios.patch(
      `${API_URL}/api/orders/${orderId}`, // Your API endpoint for updating order status
      {
        status: newStatus, // New status value (e.g., 'pending', 'shipped', etc.)
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("dashtoken")}`, // Ensure to pass token if authentication is required
        },
      }
    );

    if (response.status === 200) {
      message.success("Order status updated successfully");
      return response.data; // Handle response if needed
    }
  } catch (error: any) {
    message.error(
      error?.response?.data?.message || "Failed to update order status"
    );
  }
};

const rowSelection: TableProps<any>["rowSelection"] = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows:",
      selectedRows
    );
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === "Disabled User", // Example condition
    name: record.name,
  }),
};

const handleMenuStatusChange = async (newStatus: string, orderId: string) => {
  const result = await updateOrderStatus(orderId, newStatus);
  if (result) {
    console.log("Order status updated:", result);
  }
};

const handleStatusChange = async (value: string, orderId: string) => {
  try {
    const response = await axios.put(`${API_URL}/api/orders/${orderId}`, {
      status: value,
    });
    console.log("Order Status Updated:", response.data);
  } catch (error) {
    console.error("Error updating order status:", error);
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "90%",
  overflowY: "scroll",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
};

const Neworders = () => {
  const [data, setData] = useState<DataType[]>([]); // Filtered data
  const [originalData, setOriginalData] = useState<DataType[]>([]); // Unfiltered original data
  const [paymentType, setPaymentType] = useState<string | undefined>(undefined);
  const [orderStatus, setOrderStatus] = useState<string | undefined>(undefined);
  const [orderId, setOrderId] = useState<string>("");
  const [ogData, setOgData] = useState<OrderData[]>([]);
  const [viewDta, setViewDta] = useState<OrderData[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleViewOrders = (id: any) => {
    const filterdts = ogData.filter((val) => val._id === id);
    console.log(filterdts);
    setViewDta(filterdts);
    if (viewDta) {
      handleOpen();
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Order ID",
      dataIndex: "Orderid",
      key: "Orderid",
      render: (text) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {text.slice(-5)}
        </p>
      ),
    },
    {
      title: "Ordered Date",
      dataIndex: "OrderedDate",
      key: "OrderedDate",
      render: (text) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {text}
        </p>
      ),
    },
    {
      title: "Total Items",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {quantity}
        </p>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "TotalPrize",
      key: "TotalPrize",
      render: (text, record) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>
            ₹ {text}
          </p>
        </>
      ),
    },
    {
      title: "Shipping Address",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <p className="mb-0" style={{ color: "#444444" }}>
            {text}
          </p>
          <span style={{ color: "#6C6C6C" }}>{record.Shippingaddress}</span>
        </>
      ),
    },
    {
      title: "Payment",
      dataIndex: "Payment",
      key: "Payment",
      render: (text) => (
        <p className="mb-0" style={{ color: "#444444" }}>
          {text}
        </p>
      ),
    },
    {
      title: "View All",
      dataIndex: "Orderid",
      key: "Orderid",
      render: (Orderid) => (
        <span
          onClick={() => handleViewOrders(Orderid)}
          style={{
            color: "#444444",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          View
        </span>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (status, record) => (
        <Select
          value={status}
          onChange={(newStatus) =>
            handleMenuStatusChange(newStatus, record.Orderid)
          }
          className="statusDrop"
          style={{
            width: "100%",
            backgroundColor: "#fff",
            color: "#444444",
          }}
        >
          <Select.Option value="pending">Pending</Select.Option>
          <Select.Option value="shipping">shipping</Select.Option>
          <Select.Option value="out for delivery">
            out for delivery
          </Select.Option>
        </Select>
      ),
    },
  ];

  // Fetching data from the API using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/orders/total-orders`);
        const orders = response.data?.data?.orders;
        setOgData(orders);
        if (Array.isArray(orders)) {
          // Get the current date and calculate the date 7 days ago
          const currentDate = new Date();
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(currentDate.getDate() - 7);

          // Filter orders based on `createdAt` within the last 7 days
          const filteredOrders = orders.filter((order) => {
            const createdAtDate = new Date(order.createdAt);
            return (
              createdAtDate >= sevenDaysAgo && createdAtDate <= currentDate
            );
          });

          // Transform the filtered data
          const transformedData = filteredOrders.map((order) => ({
            key: order._id,
            Orderid: order._id,
            OrderedDate: new Date(order.orderDate).toLocaleDateString(), // Format date as needed
            Productname: order.products[0]?.productName || "N/A", // Assuming first product name
            specs: `${order.products[0]?.storage || ""} / ${
              order.products[0]?.colorName || ""
            }`, // Storage and color
            TotalPrize: order.finalAmount,
            mrpPrice: order.totalAmount,
            Shippingaddress: `${order.shippingAddress.houseNumberAndFloor}, ${order.shippingAddress.buildingAndBlockNumber}, ${order.shippingAddress.locality}, ${order.shippingAddress.pincode}`,
            name: `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,
            Payment: order.paymentMethod,
            orderStatus: order.status,
            quantity: order.products.length,
          }));

          setOriginalData(transformedData); // Save original data
          setData(transformedData); // Set filtered data initially as all data
        } else {
          console.error(
            "Orders response is not in expected format:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchData();
  }, []);

  // Handle filtering
  const applyFilters = () => {
    let filteredData = [...originalData];

    // Apply Order ID filter
    if (orderId.trim()) {
      filteredData = filteredData.filter((item) =>
        item.Orderid.toLowerCase().includes(orderId.trim().toLowerCase())
      );
    }

    // Apply Payment Type filter
    if (paymentType) {
      filteredData = filteredData.filter(
        (item) => item.Payment.toLowerCase() === paymentType.toLowerCase()
      );
    }

    // Apply Order Status filter
    if (orderStatus) {
      filteredData = filteredData.filter(
        (item) => item.orderStatus.toLowerCase() === orderStatus.toLowerCase()
      );
    }

    setData(filteredData); // Update the table data
  };

  // Reset Filters
  const handleReset = () => {
    setOrderId("");
    setPaymentType(undefined);
    setOrderStatus(undefined);
    setData(originalData); // Reset to original data
  };


  const pdfRef = useRef<HTMLDivElement | null>(null);

  const handleDownloadPdf = () => {
    const element = pdfRef.current;
    const options = {
      margin: 0.5,
      filename: `Order_${viewDta[0]?._id.slice(-5)}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };


  return (
    <>
      {/* <div className="d-flex justify-content-end mt-3">
        <div>
          <Dropdown>
            <Dropdown.Toggle className="btn-down">Download</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">Order</Dropdown.Item>
              <Dropdown.Item href="">Invoice</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div> */}

      <div className="select-combine mt-5">
        <div>
          <label className="brand-label">Order id</label>
          <br />
          <Input
            className="select-brand"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
        </div>
        <div>
          <label className="brand-label">Payment type</label>
          <br />
          <Select
            value={paymentType}
            onChange={(value) => setPaymentType(value)}
            className="select-brand"
            options={[
              { value: "cod", label: "COD" },
              { value: "Upi", label: "UPI" },
              { value: "Card", label: "Card" },
            ]}
            suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
          />
        </div>

        <div>
          <label className="brand-label">Order status</label>
          <br />
          <Select
            value={orderStatus}
            onChange={(value) => setOrderStatus(value)}
            className="select-brand"
            options={[
              { value: "pending", label: "pending" },
              { value: "packed", label: "packed" },
              { value: "shipping", label: "shipping" },
            ]}
            suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
          />
        </div>

        <div style={{ marginTop: "28px" }}>
          <Button className="btn-1" onClick={applyFilters}>
            <IoSearch />
          </Button>
          <Button
            className="btn-1"
            style={{ marginLeft: "10px" }}
            onClick={handleReset}
          >
            <GrPowerReset />
          </Button>
        </div>
      </div>

      <div className="pt-3">
        <Table<DataType>
          className="custom-table"
          rowSelection={{ ...rowSelection }}
          columns={columns}
          pagination={false}
          dataSource={data} // Use the filtered data here
        />
      </div>

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div ref={pdfRef}>
              <div className="d-flex justify-content-between pb-3">
                <div>
                  <h3 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Order Details
                  </h3>

                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order ID:</strong>{" "}
                    {viewDta[0]?._id.slice(-5)}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Order Date:</strong>{" "}
                    {new Date(viewDta[0]?.orderDate).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Method:</strong>{" "}
                    {viewDta[0]?.paymentMethod}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Payment Status:</strong>{" "}
                    {viewDta[0]?.paymentStatus}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Exchange Type:</strong>{" "}
                    {viewDta[0]?.exchange === true
                      ? "With Exchange"
                      : "Without exchange"}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Delivery Type:</strong>{" "}
                    {viewDta[0]?.deliveryType}
                  </p>
                  <p className="mb-0">
                    <strong style={{ fontWeight: 500 }}>Delivery Date:</strong>{" "}
                    {new Date(viewDta[0]?.deliveryDate).toLocaleDateString()}
                  </p>
                </div>

                <div>
                  <h3
                    className=""
                    style={{ fontSize: "18px", fontWeight: 600 }}
                  >
                    Shipping Address
                  </h3>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.firstName}{" "}
                    {viewDta[0]?.shippingAddress?.lastName}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.houseNumberAndFloor}
                  </p>
                  <p className="mb-0">
                    {viewDta[0]?.shippingAddress?.locality}
                  </p>
                  <p className="mb-0">{viewDta[0]?.shippingAddress?.pincode}</p>
                </div>
              </div>
              <TableContainer component={Paper}>
                <TableM aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell>Product Price</TableCell>
                      <TableCell>DiscountedPrice Price</TableCell>
                      <TableCell>discountInPercent Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Storage</TableCell>
                      <TableCell>Categories</TableCell>
                      <TableCell>Color</TableCell>
                      {/* <TableCell align="right">variantId</TableCell> */}
                      <TableCell>Ram</TableCell>
                    </TableRow>
                  </TableHead>

                  {viewDta.length > 0 &&
                    viewDta[0].products?.map((row: any) => (
                      <>
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row._id.slice(-5)}
                          </TableCell>
                          <TableCell align="center">
                            {row.productPrice ? row.productPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.discountedPrice ? row.discountedPrice : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.discountInPercent
                              ? row.discountInPercent
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.quantity ? row.quantity : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.storage ? row.storage : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.categories ? row.categories : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.colorName ? row.colorName : "-"}
                          </TableCell>
                          {/* <TableCell align="right">
      {row?.variantId.slice(-5)}
    </TableCell> */}
                          <TableCell align="center">
                            {row.ram ? row.ram : "-"}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableM>
              </TableContainer>
              </div>
              <div className="text-center py-3">
                <Button onClick={handleClose} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="me-1">Close</Button>
                <Button onClick={handleDownloadPdf} style={{width:"100px",backgroundColor:"#000",color:"#fff",border:"1px solid #000"}} className="ms-1">Download</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Neworders;
