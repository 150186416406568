import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import ReactApexChart from "react-apexcharts";
import type { MenuProps } from "antd";
import { Dropdown, Select, Space, Spin } from "antd";
import axios from "axios";
import { API_URL } from "../Api/Config";
import { MenuItem } from "@mui/material";

const items: MenuProps["items"] = [
  {
    label: "Today",
    key: "0",
  },
  {
    label: "Last Week",
    key: "1",
  },
  {
    label: "Last Month",
    key: "2",
  },
];

const Salesperformance = () => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [performreportData, setPerformReportData] = useState<any[]>([]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["Smart Phone", "Tab", "Smart Watch"],
      datasets: [
        {
          label: "Completed orders",
          backgroundColor: "#231F20",
          borderColor: "#231F20",
          borderWidth: 2,
          borderRadius: {
            topLeft: 15,
            topRight: 15,
          }, // Thicker border for a defined look
          barThickness: 10,
          data: [
            performreportData[0]?.completed,
            performreportData[1]?.completed,
            performreportData[2]?.completed,
          ],
        },
        {
          label: "Canceled orders",
          backgroundColor: "#CACACA",
          borderColor: "#CACACA",
          borderWidth: 2,
          barThickness: 10,
          fontSize: "14px",
          fontWeight: "400",
          borderRadius: {
            topLeft: 15,
            topRight: 15,
          },
          data: [
            performreportData[0]?.cancelled,
            performreportData[1]?.cancelled,
            performreportData[2]?.cancelled,
          ],
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          position: "top", // Moves the legend to the top
          align: "start",
          labels: {
            color: textColor,
            boxWidth: 8, // Set box width to 10px
            boxHeight: 8,
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Products", // X-axis title
            color: textColorSecondary,
            font: {
              size: 16, // Font size for the title
              weight: "bold", // Font weight for the title
            },
          },
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: true, // No vertical grid lines
            drawBorder: true, // Draw the x-axis border
            color: surfaceBorder, // X-axis grid line color
          },
        },
        y: {
          title: {
            display: true,
            text: "Orders", // Y-axis title
            color: textColorSecondary,
            font: {
              size: 16, // Font size for the title
              weight: "bold", // Font weight for the title
            },
          },
          ticks: {
            color: textColorSecondary,
            beginAtZero: true, // Y-axis starts at 0
            stepSize: 200, // Set the step size for the y-axis
            callback: function (value: string) {
              return value;
            },
          },
          grid: {
            color: surfaceBorder,
            drawBorder: true,
            borderDash: [4, 4],
          },
          min: 0, // Set the minimum value for y-axis
          max: 1000, // Set the maximum value for y-axis
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  //RadicalChart
  const [reportData, setReportData] = useState({
    newOrders: 0,
    completedOrders: 0,
    replacedOrders: 0,
    canceledOrders: 0,
  });

  const chartDataRadi = {
    series: [
      reportData?.newOrders || 0,
      reportData?.canceledOrders || 0,
      reportData?.completedOrders || 0,
      reportData?.replacedOrders || 0,
    ],
    options: {
      chart: {
        type: "radialBar" as const,
        height: 100,
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 350,
          hollow: {
            margin: 1,
            size: "40%",
            background: "transparent",
          },
        },
        dataLabels: {
          name: {
            fontSize: "6px",
            color: undefined,
            offsetY: -10,
          },
          value: {
            fontSize: "10px",
            color: "#FFF",
          },
        },
      },
      colors: ["#000", "#4a4a4a", "#919191", "#bebbbb"],
      labels: [
        "New orders",
        "Cancelled orders",
        "Completed orders",
        "Replaced orders",
      ],
    },
  };

  const colors = ["#000", "#4a4a4a", "#919191", "#bebbbb"];
  const [dropvalue, setDropvalue] = useState("today");
  const [performdropvalue, setperformDropvalue] = useState("today");

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDropvalue(value);
  };

  const handlepreformChange = (value: string) => {
    console.log(`selected ${value}`);
    setperformDropvalue(value);
  };

  const fetchReportData = async () => {
    if (dropvalue === "today") {
      const today = await axios.get(
        `${API_URL}/api/report/orders-perfomance?period=today`
      );
      setReportData(today.data.radial);
    } else if (dropvalue === "lastweek") {
      const today = await axios.get(
        `${API_URL}/api/report/orders-perfomance?period=lastWeek`
      );
      setReportData(today.data.radial);
    } else {
      const today = await axios.get(
        `${API_URL}/api/report/orders-perfomance?period=lastMonth`
      );
      setReportData(today.data.radial);
    }
  };

  const fetchPerformReportData = async () => {
    if (performdropvalue === "today") {
      const today = await axios.get(
        `${API_URL}/api/report/sales-perfomance?period=today`
      );
      setPerformReportData(today.data.data);
    } else if (performdropvalue === "lastweek") {
      const today = await axios.get(
        `${API_URL}/api/report/sales-perfomance?period=lastWeek`
      );
      setPerformReportData(today.data.data);
    } else {
      const today = await axios.get(
        `${API_URL}/api/report/sales-perfomance?period=lastMonth`
      );
      setPerformReportData(today.data.data);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [dropvalue]);

  useEffect(() => {
    fetchPerformReportData();
  }, [performdropvalue]);

  if (!reportData) {
    return (
      <div className="text-center">
        <Spin className="custom-spinner" />
      </div>
    );
  }

  return (
    <>
      <section className="section-main">
        <div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 p-2">
              <h2 className="head-txt pb-2">Sales Performance </h2>
              <div className="card d-flex justify-content-start align-items-center">
                <Chart
                  className="charts"
                  type="bar"
                  data={chartData}
                  options={chartOptions}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    zIndex: 10,
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <Select
                      defaultValue="today"
                      style={{ width: 120 }}
                      className="custom-select"
                      onChange={handlepreformChange}
                      options={[
                        { value: "today", label: "Today" },
                        { value: "lastweek", label: "Last Week" },
                        { value: "lastmonth", label: "Last Month" },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 p-2 ">
              <h2 className="head-txt pb-2">Orders Performance</h2>
              <div className="card">
                <div
                  id="chart"
                  style={{
                    position: "relative",
                    textAlign: "center",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  {chartDataRadi.series.length > 0 && chartDataRadi.options ? (
                    <>
                      <ReactApexChart
                        options={chartDataRadi.options}
                        series={chartDataRadi.series}
                        type="radialBar"
                        height={200}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "10px",
                          zIndex: 10,
                          cursor: "pointer",
                        }}
                      >
                        <div>
                          <Select
                            defaultValue="today"
                            style={{ width: 120 }}
                            className="custom-select"
                            onChange={handleChange}
                            options={[
                              { value: "today", label: "Today" },
                              { value: "lastweek", label: "Last Week" },
                              { value: "lastmonth", label: "Last Month" },
                            ]}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        {/* Div for "New Orders" and "Canceled Orders" */}
                        <div style={{ width: "50%" }}>
                          <div className="labels-chart-order d-flex">
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: colors[0],
                                margin: "0 auto",
                                marginTop: "2px",
                              }}
                            />
                            <div className="chart-label-option-2">
                              New Orders
                            </div>
                          </div>
                          <div className="labels-chart-order d-flex">
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: colors[1],
                                margin: "0 auto",
                                marginTop: "2px",
                              }}
                            />
                            <div className="chart-label-option-2">
                              Completed Orders
                            </div>
                          </div>
                        </div>

                        <div style={{ width: "50%" }}>
                          {/* Column for "Completed Orders" */}
                          <div className="labels-chart-order d-flex">
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: colors[3],
                                margin: "0 auto",
                                marginTop: "2px",
                              }}
                            />
                            <div className="chart-label-option-2">
                              Canceled Orders
                            </div>
                          </div>

                          {/* Column for "Replaced Orders" */}
                          <div className="labels-chart-order d-flex">
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: colors[2],
                                margin: "0 auto",
                                marginTop: "2px",
                              }}
                            />
                            <div className="chart-label-option-2">
                              Replaced Orders
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>Loading chart...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Salesperformance;
