import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { MdMailOutline } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../Api/Config";

const Forgetpassword = () => {
  const [email, setEmail] = useState(""); // State to store the email input
  const [loading, setLoading] = useState(false); // Loading state for the button
  const navigate = useNavigate();

  const getAuthToken = () => {
    return localStorage.getItem("dashtoken");
  };

  const getHeaders = () => {
    const token = getAuthToken();
    return {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  };

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const adminForgotPassword = async () => {
    if (!isValidEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    setLoading(true); // Set loading to true when request starts

    try {
      const response = await axios.post(
        `${API_URL}/api/user/auth/forgotPassword`,
        { email }, // Email as a plain string
        { headers: getHeaders() }
      );

      if (response.status === 200) {
        message.success("Password reset OTP sent to your email.");
        localStorage.setItem("email", email);
        navigate('/otp-verify'); // Redirect after success
      } else {
        message.error(response.data.message || "Password reset failed.");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
      console.error("Forgot Password error:", error);
    } finally {
      setLoading(false); // Set loading to false after request completes
    }
  };

  return (
    <section>
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login-1">
              <div className="login-logo">
                <img
                  src="/assets/logo.png"
                  className="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="login-form">
              <div>
                <h2 className="head-txt text-center">Forget Password</h2>
                <h6 className="login-txt-1">Enter Email Id </h6>
                <p className="login-txt-2">
                  Enter your official Email to get the password in the mail
                </p>

                <div className="login-div">
                  <label>Email Id</label>
                  <br />
                  <Input
                    size="large"
                    suffix={<MdMailOutline />}
                    value={email} // Attach value to email state
                    onChange={(e) => setEmail(e.target.value)} // Update email state on change
                  />
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <Button
                    onClick={adminForgotPassword}
                    className="btn-1"
                    loading={loading} // Show loading state on button
                    disabled={loading} // Disable button when loading
                  >
                    Request
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default  Forgetpassword;
