import React, { useState, useEffect } from "react";
import { Input } from "antd";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams to get the id from URL
import axios from "axios";
import { API_URL } from "../Api/Config"; // Import the API URL

const EditDelivery = () => {
  const { id } = useParams<{ id: string }>(); // Get the id from the URL params
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    pincode: "",
    standardDeliveryDate: "",
    expressDeliveryDate: "",
  });
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch existing delivery info when the component mounts
  useEffect(() => {
    const fetchDeliveryData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/delivery-info/${id}`);
        setFormData(response.data); // Set the fetched data into the form state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDeliveryData();
  }, [id]); // Runs when component mounts or id changes

  // Handle input change
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission (update delivery info)
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      // Make the PUT request to update the delivery info
      const response = await axios.patch(`${API_URL}/api/delivery-info/${id}`, formData);
      console.log("Data updated successfully:", response.data);
      // Optionally, navigate to another page after success
      navigate("/set-estimate-delivery");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <div className="head-txt d-flex ">
        <div className="icons-div">
          <i className="fi fi-rr-caret-left "></i>
        </div>
        <div>Edit Location</div>
      </div>
      <div className="select-combine justify-content-end align-items-end">
        <div className="mt-4 ">
          <Button className="btn-1" onClick={() => navigate("/set-estimate-delivery")}>
            Back to List
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label className="addbrand-label">State*</label>
          <br />
          <Input
            className="brand-input"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">District*</label>
          <br />
          <Input
            className="brand-input"
            name="district"
            value={formData.district}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Pincode (First 4digits)*</label>
          <br />
          <Input
            className="brand-input"
            name="pincode"
            value={formData.pincode}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Standard Delivery</label>
          <br />
          <Input
            className="brand-input"
            name="standardDeliveryDate"
            value={formData.standardDeliveryDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Express Delivery*</label>
          <br />
          <Input
            className="brand-input"
            name="expressDeliveryDate"
            value={formData.expressDeliveryDate}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-4">
          <Button type="submit" className="btn-1">
            Save Changes
          </Button>
        </div>
      </form>
    </section>
  );
};

export default EditDelivery;
