import React from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import { Input, Table, type MenuProps } from "antd";
import type { DatePickerProps, TableColumnsType, TableProps } from "antd";
import { DatePicker, Space } from "antd";

const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  console.log(date, dateString);
};

const label = { inputProps: { "aria-label": "Size switch demo" } };

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

interface DataType {
  key: React.Key;
  //   brand: string;
  orderid: string;
  ordereddate: string;
  transactionid: string;
  account: string;
  productname: string;
  totalprize: string;
  paymentdate: string;
  paymentmode: string;
}

const data: DataType[] = [
  {
    key: "1",
    orderid: "SM1001",
    ordereddate: "21-09-2024",
    transactionid: "5645684564",
    account: "5645684564",
    productname: "Samsung Galaxy A15 5G",
    totalprize: "19,499.00",
    paymentdate: "21-09-2024",
    paymentmode: "UPI",
  },
  {
    key: "2",
    orderid: "SM1001",
    ordereddate: "21-09-2024",
    transactionid: "5645684564",
    account: "5645684564",
    productname: "Samsung Galaxy A15 5G",
    totalprize: "19,499.00",
    paymentdate: "21-09-2024",
    paymentmode: "Card",
  },
  {
    key: "3",
    orderid: "SM1001",
    ordereddate: "21-09-2024",
    transactionid: "5645684564",
    account: "5645684564",
    productname: "Samsung Galaxy A15 5G",
    totalprize: "19,499.00",
    paymentdate: "21-09-2024",
    paymentmode: "COD",
  },
  {
    key: "4",
    orderid: "SM1001",
    ordereddate: "21-09-2024",
    transactionid: "5645684564",
    account: "5645684564",
    productname: "Samsung Galaxy A15 5G",
    totalprize: "19,499.00",
    paymentdate: "21-09-2024",
    paymentmode: "UPI",
  },
];

const columns: TableColumnsType<DataType> = [
  {
    title: "Order id",
    dataIndex: "orderid",
  },
  {
    title: "Ordered Date",
    dataIndex: "ordereddate",
  },
  {
    title: "Transaction Id ",
    dataIndex: "transactionid",
    render:(transactionid: string) => (
      <>
        <div style={{fontSize:'13px',fontWeight:'400'}}>
            {transactionid}
        </div>
      </>
    ),
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Product name",
    dataIndex: "productname",
    render: (productname: string) => (
      <>
        <div>{productname}</div>
        <span className="table-txt-m">( 8GB RAM , 256GM ROM , Black )</span>
      </>
    ),
  },
  {
    title: "Total Prize",
    dataIndex: "totalprize",
    render: (totalprize: string) => (
      <>
        <div>&#8377;{totalprize}</div>
      </>
    ),
  },
  {
    title: "Payment Date",
    dataIndex: "paymentdate",
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentmode",
  },
];

const rowSelection: TableProps<DataType>["rowSelection"] = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
const Transaction = () => {
  return (
    <>
      <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Transaction</h2>

            <div className="d-flex justify-content-end">
              <div>
                <Dropdown>
                  <Dropdown.Toggle className="btn-down">
                    Download
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="">Order</Dropdown.Item>
                    <Dropdown.Item href="">Invoice</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="select-combine pt-3">
              <div>
                <label className="brand-label">Transaction id</label>
                <br />
                <Input className="select-brand" />
              </div>

              <div>
                <label className="brand-label">Month</label>
                <br />
                <DatePicker
                  className="select-brand-1"
                  onChange={onChange}
                  picker="month"
                />
              </div>

              <div>
                <label className="brand-label">Payment date</label>
                <br />
                <DatePicker className="select-brand-1" onChange={onChange} />
              </div>

              <div className="mt-4">
                <Button className="btn-1">Apply</Button>
              </div>
            </div>

            <div>
              <div className="pt-4">
                <Table<DataType>
                  className="custom-table"
                  rowSelection={{ ...rowSelection }}
                  columns={columns}
                  pagination={false}
                  dataSource={data}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Transaction;
