import React, { useState } from "react";
import { Descriptions, message, Upload } from "antd";
import { Input } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { Button, TabContainer } from "react-bootstrap";
import colorName from "color-name";
import e from "express";
import axios from "axios";
import { Button as ButtonA } from "antd";
import { ValueType } from "rc-input/lib/interface";
import { RcFile } from "antd/es/upload";
import AddVariant from "./AddVarinat";
import { Variants } from "antd/es/config-provider";
import AddTabVariant from "./addTabVariant";

const Tab = () => {
  const [productData, setProductData] = useState<any[]>([]);

  const [product, setProduct] = useState({
    slug: "",
    brand: "",
    product: "",
    categories: "Tab",
    connectivity: {
      bluetooth: "",
      network: "",
    },
    boughtTogether: [],
    variants: productData,
    // specialEdition: {
    //   color: "",
    //   variants: [
    //     {
    //       variantName: "",
    //       ramSize: "",
    //       storage: "",
    //       colorCode: "",
    //       colorName: "",
    //       images: [],
    //       discount: "",
    //       price: 0,
    //       dealPrice: 0,
    //       dealCountDownTime: new Date(),
    //       inStock: true,
    //     },
    //   ],
    // },
    description: {
      brand: "",
      model: "",
      screenSize: "",
      camera: "",
      battery: "",
      processor: "",
    },
    detailsOverview: {
      overview: "",
      display: "",
      performance: "",
      cameraSystem: "",
      batteryLife: "",
      connectivity: "",
      softwareAndFeatures: "",
    },
    additionalOverview: {
      operatingSystem: "",
      connectivityTech: "",
      displayType: "",
      processor: "",
      gpsNavigation: "",
      cellularTechnology: "",
      weight: "",
      waterResistance: "",
    },
  });

  const handleProductSave = async () => {
    console.log(productData);
    
    try {
      const transformedVariants = productData.map((variant: any) => ({
        ...variant,
        images: variant.images.map((image: any) =>
          typeof image === 'object' && image.originFileObj
            ? image.base64 // Use the name property or upload to get the URL
            : image // If already a string (e.g., URL), use as-is
        ),
      }));
      const dtas = { ...product, variants: transformedVariants };

      // Initialize FormData and append the data
      const formData = new FormData();
  
      const appendToFormData = (formData: FormData, data: any, parentKey = '') => {
        if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
          Object.keys(data).forEach((key) => {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            appendToFormData(formData, data[key], fullKey);
          });
        } else if (Array.isArray(data)) {
          data.forEach((item, index) => {
            const fullKey = `${parentKey}[${index}]`;
            appendToFormData(formData, item, fullKey);
          });
        } else {
          formData.append(parentKey, data);
        }
      };
  
      appendToFormData(formData, dtas);
  
      // Make the POST request
      const result = await axios.post('https://vnext-api.onrender.com/api/products', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      console.log('Product saved successfully:', result.data);
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };
  
  

  const postProductDetails = async (product: any) => {
    try {
      // Make a POST request to your API with the product data
      const response = await axios.post(
        `https://vnext-api.onrender.com/api/products`,
        product,  {
        headers: {
          "Content-Type": "multipart/form-data", // Important for sending FormData
        },
      }
      );
      return response.data; 
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error posting product details:", error);
      throw error; 
    }
  };

  const handleProductUpdate = (updatedProduct: any) => {
    setProductData(updatedProduct);
  };
  return (
    <>
      <section>
        <div className="row py-4">
          {/* Col-1 */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div>
            <label className="addbrand-label">Slug</label>
              <br />
              <Input
                className="brand-input"
                value={product.slug}
                onChange={(e) =>
                  setProduct({ ...product, slug: e.target.value })
                }
              />
              <br />
              <label className="addbrand-label">Product name*</label>
              <br />
              <Input
                className="brand-input"
                value={product.product}
                onChange={(e) =>
                  setProduct({ ...product, product: e.target.value })
                }
              />
              <br />
              <label className="addbrand-label">Brand*</label>
              <br />
              <Input
                className="brand-input"
                value={product.brand}
                onChange={(e) =>
                  setProduct({ ...product, brand: e.target.value })
                }
              />
              <br />
              <br />
              <label className="addbrand-label">Description*</label>
              <div className="input-combine">
                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Brand</label>
                  </div>
                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.description.brand}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          description: {
                            ...product.description,
                            brand: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />
                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Modal</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          description: {
                            ...product.description,
                            model: e.target.value,
                          },
                        })
                      }
                      value={product.description.model}
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Screen Size</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.description.screenSize}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          description: {
                            ...product.description,
                            screenSize: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Camera</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.description.camera}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          description: {
                            ...product.description,
                            camera: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Battery</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.description.battery}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          description: {
                            ...product.description,
                            battery: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Processor</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.description.processor}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          description: {
                            ...product.description,
                            processor: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />
              </div>
              <br />

              <label className="addbrand-label">Detail Overview*</label>

              <div className="input-combine">
                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Overview</label>
                  </div>

                  <div>
                    <TextArea
                      className="brand-input-text"
                      value={product.detailsOverview.overview}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            overview: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div mt-5">
                  <div>
                    <label className="label-3">Display </label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.detailsOverview.display}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            display: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />
                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Performance</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.detailsOverview.performance}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            performance: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Camera System</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.detailsOverview.cameraSystem}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            cameraSystem: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Batterylife</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.detailsOverview.batteryLife}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            batteryLife: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Connectivity & 5G</label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.detailsOverview.connectivity}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            connectivity: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="input-combine-div">
                  <div>
                    <label className="label-3">Software & feature </label>
                  </div>

                  <div>
                    <Input
                      className="brand-input-1"
                      value={product.detailsOverview.softwareAndFeatures}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          detailsOverview: {
                            ...product.detailsOverview,
                            softwareAndFeatures: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Col-2 */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div>
              {/* additonal overview */}
              <div className="mt-3">
                <label className="addbrand-label">Additional Overview*</label>

                <div className="input-combine">
                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Operating System</label>
                    </div>

                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.operatingSystem}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              operatingSystem: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Connectivity Tech</label>
                    </div>
                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.connectivityTech}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              connectivityTech: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Display Type </label>
                    </div>
                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.displayType}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              displayType: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Processor </label>
                    </div>
                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.processor}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              processor: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">GPS Navigation </label>
                    </div>
                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.gpsNavigation}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              gpsNavigation: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Cellular Technology </label>
                    </div>
                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.cellularTechnology}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              cellularTechnology: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />

                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Weight</label>
                    </div>

                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.weight}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              weight: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />

                  <div className="input-combine-div">
                    <div>
                      <label className="label-3">Water Resistance</label>
                    </div>

                    <div>
                      <Input
                        className="brand-input-1"
                        value={product.additionalOverview.waterResistance}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalOverview: {
                              ...product.additionalOverview,
                              waterResistance: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <AddTabVariant onProductUpdate={handleProductUpdate} />
          <div className="button-settings">
            <div>
              <Button className="btn-2">Cancel</Button>
            </div>

            <div>
              <Button className="btn-1" onClick={handleProductSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tab;
