import React, { useState } from "react";
import { Input } from "antd";
import { Button, Container } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { API_URL } from "../Api/Config";

// Defining the keys of the 'access' object as a union type
type AccessKey = "home" | "brand" | "products" | "orders" | "banner" | "transaction";

interface FormData {
  name: string;
  position: string;
  email: string;
  password: string;
  access: {
    [key in AccessKey]: boolean; // This ensures the correct types for each access key
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddSubadmin: React.FC = () => {
  // Initialize form data with empty values
  const [formData, setFormData] = useState<FormData>({
    name: "",
    position: "",
    email: "",
    password: "",
    access: {
      home: false,
      brand: false,
      products: false,
      orders: false,
      banner: false,
      transaction: false,
    },
  });

  // Handle text input field changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle checkbox changes for access rights
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      access: {
        ...formData.access,
        [name as AccessKey]: checked, // TypeScript knows 'name' is a valid AccessKey here
      },
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      // Sending form data via POST request
      const response = await axios.post(
       `${API_URL}/api/subadmin/auth/create`,
       formData
      );
      console.log("Subadmin created successfully:", response.data);
      // Optionally, redirect or show a success message
    } catch (error) {
      console.error("Error saving subadmin:", error);
    }
  };

  // Defining the access keys array explicitly typed as AccessKey[]
  const accessKeys: AccessKey[] = ["home", "brand", "products", "orders", "banner", "transaction"];

  return (
    <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
      <Container>
        <div>
          <div className="head-txt d-flex">
            <div className="icons-div">
              <i className="fi fi-rr-caret-left "></i>
            </div>
            <div>Add Sub Admin</div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="mt-4">
                  <div>
                    <label className="brand-label">Name*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Position*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="position"
                      value={formData.position}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Email Id*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-lg-3">
                    <label className="brand-label">Password*</label>
                    <br />
                    <Input
                      className="brand-input"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="p-3">
                  <p className="txt-1">Access*</p>

                  <div className="admin-checkbox">
                    {accessKeys.map((accessKey: AccessKey) => (
                      <div className="d-flex gap-2" key={accessKey}>
                        <div>
                          <Checkbox
                            {...label}
                            size="small"
                            name={accessKey}
                            checked={formData.access[accessKey]}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="checkbox-admin">
                          <label className="categories-label">
                            {accessKey.charAt(0).toUpperCase() + accessKey.slice(1)}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="button-settings">
                <div>
                  <Button className="btn-2" type="button">
                    Cancel
                  </Button>
                </div>

                <div>
                  <Button className="btn-1" type="submit"
                  onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default AddSubadmin;
