import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Input } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Api/Config";

const Domain = () => {
  const [domains, setDomains] = useState<any[]>([]);
  const [filteredDomains, setFilteredDomains] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Filter states
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [domainNameFilter, setDomainNameFilter] = useState("");
  const [contactPersonFilter, setContactPersonFilter] = useState("");

  // To track if apply button was clicked
  const [isApplied, setIsApplied] = useState(false);

  // Fetch domains data from API
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/domains`); // Replace with your API endpoint
        setDomains(response.data.domains); // Assuming the data has a 'domains' field
        setFilteredDomains(response.data.domains); // Set filtered domains initially to all domains
        setLoading(false);
      } catch (error) {
        console.error("Error fetching domains:", error);
        setLoading(false);
      }
    };

    fetchDomains();
  }, []);

  // Filter domains based on input values
  const filterDomains = () => {
    const filtered = domains.filter((domain) => {
      return (
        domain.companyName.toLowerCase().includes(companyNameFilter.toLowerCase()) &&
        domain.domainName.toLowerCase().includes(domainNameFilter.toLowerCase()) &&
        domain.contactPersonName.toLowerCase().includes(contactPersonFilter.toLowerCase())
      );
    });
    setFilteredDomains(filtered);
    setIsApplied(true); // Mark the filters as applied
  };

  // Reset the filters and show all domains
  const resetFilters = () => {
    setCompanyNameFilter("");
    setDomainNameFilter("");
    setContactPersonFilter("");
    setFilteredDomains(domains); // Show all domains
    setIsApplied(false); // Reset applied status
  };

  // Delete domain function
  const deleteDomain = async (domainId: string) => {
    if (window.confirm("Are you sure you want to delete this domain?")) {
      try {
        await axios.delete(`${API_URL}/api/domains/${domainId}`);
        // Update state to remove deleted domain
        setDomains((prevDomains) =>
          prevDomains.filter((domain) => domain._id !== domainId)
        );
      } catch (error) {
        console.error("Error deleting domain:", error);
      }
    }
  };

  // Update domain function (this can be extended to include an edit form/modal)
  const updateDomain = (domainId: string) => {
    // Redirect to the edit page (you can add a form for editing in that page)
    window.location.href = `/edit-domain/${domainId}`;
  };

  return (
    <>
      <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Domain</h2>

            <div className="d-flex justify-content-end">
              <div>
                <Link to="/add-domain">
                  <Button className="btn-1">Add New</Button>
                </Link>
              </div>
            </div>

            <div className="select-combine mt-5">
              <div>
                <label className="brand-label">Company Name</label>
                <br />
                <Input
                  className="select-brand"
                  value={companyNameFilter}
                  onChange={(e) => setCompanyNameFilter(e.target.value)}
                />
              </div>

              <div>
                <label className="brand-label">Domain Name</label>
                <br />
                <Input
                  className="select-brand"
                  value={domainNameFilter}
                  onChange={(e) => setDomainNameFilter(e.target.value)}
                />
              </div>

              <div>
                <label className="brand-label">Contact Person</label>
                <br />
                <Input
                  className="select-brand"
                  value={contactPersonFilter}
                  onChange={(e) => setContactPersonFilter(e.target.value)}
                />
              </div>

              <div className="d-flex align-items-center gap-3" style={{ marginTop: "28px" }}>
              <Button className="btn-1" onClick={filterDomains}>
                  Apply
                </Button>
                <Button className="btn-1" onClick={resetFilters}>
                  Reset
                </Button>
                
              </div>
            </div>
            <div className="pt-3">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="setting-data">S.No</TableCell>
                        <TableCell align="right" className="setting-data">
                          Company Name
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Domain Name
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Contact Person Name
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Phone No
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Location
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Users
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredDomains.map((domain, index) => (
                        <TableRow
                          key={domain._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="data-setting"
                          >
                            <div>{index + 1}</div>
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {domain.companyName}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {domain.domainName}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {domain.contactPersonName}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {domain.phoneNumber}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {domain.location}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {/* Assuming "users" is a placeholder for actual user count */}
                            0
                          </TableCell>
                          <TableCell className="data-setting">
                            <div className="d-flex gap-2 justify-content-center">
                              <div>
                                <i
                                  className="fi fi-rs-pencil edit"
                                  onClick={() => updateDomain(domain._id)}
                                ></i>
                              </div>
                              <div>
                                <i
                                  className="fi fi-rs-trash edit"
                                  onClick={() => deleteDomain(domain._id)}
                                ></i>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Domain;
