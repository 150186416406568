import React, { useEffect, useState } from "react";
import Header from "../Home/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chart } from "primereact/chart";
import { Empty, Rate } from "antd";
import { Button } from "react-bootstrap";
import { AiOutlineShoppingCart } from "react-icons/ai";
import axios from "axios";
import { API_URL } from "../Api/Config";
import DealofDay from "./DealofDay";

const Analytics = () => {
  const [activeIcon, setActiveIcon] = useState(null); // State to track the active icon

  // Function to handle icon click
  const handleIconClick = (icon: any) => {
    setActiveIcon(icon); // Set the active icon
  };

  const [bestSellingDta, setBestSellingDta] = useState<any[]>([]);

  const handleGetbestsell = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/report/best-selling`);
      setBestSellingDta(res.data.data);
      await getDeal();
    } catch (error) {
      console.warn(error);
    }
  };

  const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;

  const calculateTimeLeft = () => {
    const difference = deadline - Date.now();
    let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [dealData, setDealData] = useState<any[]>([]);
  const getDeal = async () => {
    const token = localStorage.getItem("dashtoken");

    try {
      const res = await axios.get(`${API_URL}/api/banner/dealOfTheDay`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dta = res.data.deals.filter(
        (val: any) => val.actionButton === true
      );

      console.log(dta);
      setDealData(dta);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetbestsell();
  }, []);

  return (
    <>
      <section className="section-main pt-0 top-gap">
        <div className="container">
          <div className="d-flex flex-column gap-3">
            <div className="row pt-2">
              <div className="col-lg-8 table-analytics">
                <div className="col-div">
                  <h2 className="head-txt a-txt-1 pb-2">
                    Best Selling
                    <span className="head-txt-s">(This Week) </span>
                  </h2>
                  <div>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" className="table-heads">
                              Product ID
                            </TableCell>
                            <TableCell align="left" className="table-heads">
                              Image
                            </TableCell>
                            <TableCell align="left" className="table-heads">
                              Product Name
                            </TableCell>
                            <TableCell align="left" className="table-heads">
                              Price
                            </TableCell>
                            <TableCell align="left" className="table-heads">
                              Total Sales
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {bestSellingDta.map((row) => (
                            <TableRow
                              key={row.productId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.productId}
                              </TableCell>
                              <TableCell align="left">
                                <img
                                  src={row.productImage}
                                  alt={row.productName}
                                  className="demo-img"
                                />
                              </TableCell>
                              <TableCell align="left">
                                <div>{row.productName}</div>
                              </TableCell>
                              <TableCell align="left">
                                <div>&#8377; {row.productPrice}</div>
                              </TableCell>
                              <TableCell align="left">
                                <div>{row.totalQuantitySold}</div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4">
                <div>
                  <h2 className="head-txt a-txt-2  pb-2">
                    Deal of the day <span className="head-txt-s">(Today)</span>
                  </h2>

                  <div className="col-box-2">
                    <div className="d-flex justify-content-end gap-4">
                      <div
                        className={`icons ${
                          activeIcon === "left" ? "active" : ""
                        }`}
                        onClick={() => handleIconClick("left")}
                      >
                        <i className="fi fi-rr-angle-small-left"></i>
                      </div>
                      <div
                        className={`icons ${
                          activeIcon === "right" ? "active" : ""
                        }`}
                        onClick={() => handleIconClick("right")}
                      >
                        <i className="fi fi-rr-angle-small-right"></i>
                      </div>
                    </div>

                    <div className="p-1 mt-3 pb-1 pt-4">
                      <div
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-6 col-6 d-flex justify-content-center align-items-center">
                            <div>
                              <div>
                                <img
                                  className="img-fluid"
                                  src="/assets/demo.png"
                                  style={{ width: "130px" }}
                                />
                              </div>
                              <div
                                className="d-flex gap-2 align-items-end"
                                style={{ paddingTop: "50px" }}
                              >
                                <div
                                  style={{
                                    width: "7px",
                                    height: "7px",
                                    backgroundColor: "white",
                                    border: "1px solid #000",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "7px",
                                    height: "7px",
                                    backgroundColor: "white",
                                    border: "1px solid #000",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "7px",
                                    height: "7px",
                                    backgroundColor: "white",
                                    border: "1px solid #000",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-6">
                            <div className="position-relative">
                              <img
                                src="assets/Group (1).png"
                                className="img-fluid offer-deal"
                              />

                              <h5 className="deal-title pt-5">
                                Samsung Galaxy A15 5G
                              </h5>
                              <h5 className="deal-title">(8Gb RAM , 128GB)</h5>
                              <Rate
                                disabled
                                defaultValue={2}
                                className="deal-rating"
                              />
                              <p className="deal-para">
                                ₹ 19,499.00
                                <span>
                                  MRP
                                  <span
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    ₹ 19,499.00
                                  </span>
                                </span>
                              </p>
                              <div className="d-flex gap-2">
                                <div
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    backgroundColor: "#a988d5",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    backgroundColor: "#5fbb57",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                              <div className="countdown-container-1 d-flex justify-content-between align-items-center pt-3">
                                <div className="countdown-segment countdown-hours">
                                  <div>
                                    <p className="mb-0">{timeLeft.hours}</p>
                                    <span>Hrs</span>
                                  </div>
                                </div>
                                <div>:</div>
                                <div className="countdown-segment countdown-minutes">
                                  <div>
                                    <p className="mb-0">{timeLeft.minutes}</p>
                                    <span>Min</span>
                                  </div>
                                </div>
                                <div>:</div>
                                <div className="countdown-segment countdown-seconds">
                                  <div>
                                    <p className="mb-0">{timeLeft.seconds}</p>
                                    <span>Sec</span>
                                  </div>
                                </div>
                              </div>
                              <div className="py-2">
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                  <Button variant="primary" className="buy-now">
                                    Buy Now
                                  </Button>
                                  <Button
                                    className="cart-button"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <AiOutlineShoppingCart />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4">
                <DealofDay />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Analytics;
