import React, { useState } from "react";
import {  MenuProps, Select} from "antd";
import Button from "react-bootstrap/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { Table } from "@mui/material";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const style = {
  position: "absolute" as "absolute",
  top: "10%",
  left: "86%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 2,
};

function createData(
  brand: string,
  banner: string,
  categories: string,
  performance: string,
  hotdeals: string,
  action: string
) {
  return {
    brand,
    banner,
    categories,
    performance,
    hotdeals,
    action,
  };
}

const rows = [
  createData(
    "Samsung",
    "assets/banner 1.png",
    "Smart Phone , Watch ..",
    "10 %",
    "",
    ""
  ),
  createData(
    "Vivo",
    "assets/banner 1.png",
    "Smart Phone , Watch ..",
    "10 %",
    "",
    ""
  ),
  createData(
    "Oppo",
    "assets/banner 1.png",
    "Smart Phone , Watch ..",
    "10 %",
    "",
    ""
  ),
  createData(
    "Iphone",
    "assets/banner 1.png",
    "Smart Phone , Watch ..",
    "10 %",
    "",
    ""
  ),
  createData(
    "HP",
    "assets/banner 1.png",
    "Smart Phone , Watch ..",
    "10 %",
    "",
    ""
  ),
];

const items: MenuProps["items"] = [
  {
    label: "Today",
    key: "0",
  },
  {
    label: "Last Week",
    key: "1",
  },
  {
    label: "Last Month",
    key: "2",
  },
];

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const Brands = () => {

  return (
    <>

        <section className="section-main top-gap" style={{marginLeft:'18%'}}>
          <h2 className="head-txt">Brands</h2>
          <div className="select-combine">
            <div>
              <label className="brand-label">Brands</label>
              <br />
              <Select
                onChange={handleChange}
                className="select-brand"
                options={[
                  { value: "samsung", label: "Samsung" },
                  { value: "iphone", label: "Iphone" },
                  { value: "vivo", label: "Vivo" },
                  { value: "oppo", label: "Oppo" },
                ]}
                suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
              />
            </div>

            <div>
              <label className="brand-label">Categories</label>
              <br />
              <Select
                onChange={handleChange}
                className="select-brand"
                options={[
                  { value: "smartphone", label: "Smartphone" },
                  { value: "laptops", label: "Laptops" },
                  { value: "tabs", label: "Tabs" },
                  { value: "Wacth", label: "Wacth" },
                ]}
                suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
              />
            </div>

            <div>
              <label className="brand-label">Performance</label>
              <br />
              <Select
                onChange={handleChange}
                className="select-brand"
                options={[
                  { value: "80 % - 100 %", label: "80 % - 100 %" },
                  { value: "60 % - 40 %", label: "60 % - 40 %" },
                  { value: "40 %- 20 %", label: "40 %- 20 %" },
                  { value: "20 % - 0 %", label: "20 % - 0 %" },
                ]}
                suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
              />
            </div>

            <div className="mt-4">
              <Button className="btn-1">Reset</Button>
            </div>
          </div>

          <div className="pt-3">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="brand-table-head">Brand</TableCell>
                    <TableCell className="brand-table-head">
                      Brand banner
                    </TableCell>
                    <TableCell className="brand-table-head">
                      Categories
                    </TableCell>
                    <TableCell className="brand-table-head">
                      Performance
                    </TableCell>
                    <TableCell className="brand-table-head">
                      Hot deals
                    </TableCell>
                    <TableCell className="brand-table-head">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.brand}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="brand-table-data"
                      >
                        {row.brand}
                      </TableCell>
                      <TableCell className="brand-table-data">
                        <div className="brand-img">
                          <img
                            src={row.banner}
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                      </TableCell>
                      <TableCell className="brand-table-data">
                         <div>{row.categories}</div>
                      </TableCell>
                      <TableCell className="brand-table-data">
                        <div className="d-flex gap-2 justify-content-center">
                          <div>
                            <i className="fi fi-rs-tachometer-alt-slow edit"></i>
                          </div>
                          <div>{row.performance}</div>
                        </div>
                      </TableCell>
                      <TableCell className="brand-table-data">
                        <div>
                          <Switch {...label} defaultChecked size="small" />
                          {row.hotdeals}
                        </div>
                      </TableCell>
                      <TableCell className="brand-table-data">
                        <div className="d-flex gap-2 justify-content-center">
                          {row.action}
                          <div>
                            <i className="fi fi-rs-pencil edit"></i>
                          </div>
                          <div>
                            <i className="fi fi-rs-trash edit"></i>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </div>
        </section>

    </>
  );
};

export default Brands;
