import React, { useState } from "react";
import { message, Upload, Input, Button as ButtonA, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import colorName from "color-name";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import { MdOutlineCloudUpload } from "react-icons/md";
import styled from "@emotion/styled";

interface Variant {
  price: number;
  dealPrice: string;
  colorCode?: string;
  colorName?: string;
  images?: UploadFile[];
  watchStyle?: string;
  watchSize?: string;
  watchConnection?: string;
}

const hexToRgb = (hex: any) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

const getColorNameFromRgb = (rgb: any) => {
  const closestColor = Object.entries(colorName).find(([_, value]) => {
    return value[0] === rgb[0] && value[1] === rgb[1] && value[2] === rgb[2];
  });

  if (closestColor) {
    return closestColor[0];
  } else {
    let closestName = "Unknown";
    let closestDistance = Infinity;

    Object.entries(colorName).forEach(([name, value]) => {
      const distance = Math.sqrt(
        Math.pow(value[0] - rgb[0], 2) +
          Math.pow(value[1] - rgb[1], 2) +
          Math.pow(value[2] - rgb[2], 2)
      );

      if (distance < closestDistance) {
        closestDistance = distance;
        closestName = name;
      }
    });

    return closestName;
  }
};

const StyledDragger = styled(Dragger)`
  .ant-upload-drag-icon {
    font-size: 1.5rem;
    color: #0d354f;
    margin-bottom: 10px;
  }
  .ant-upload-drag-text {
    color: #333;
    font-size: 1rem;
  }
`;

interface AddVariantProps {
  onProductUpdate: (product: any) => void;
}

const AddWatchVariant: React.FC<AddVariantProps> = ({ onProductUpdate }) => {
  const [variant, setVariant] = useState({
    variants: [
      {
        price: 0,
        dealPrice: "",
        colorCode: "",
        colorName: "",
        images: [] as UploadFile[],
        watchStyle: "",
        watchSize: "",
        watchConnection: "",
      },
    ],
  });

  const handleColorChange = (event: any, index: number) => {
    const color = event.target.value;
    const rgb = hexToRgb(color);
    const colorName = getColorNameFromRgb(rgb);

    const updatedVariants = [...variant.variants];
    updatedVariants[index].colorCode = color;
    updatedVariants[index].colorName = colorName;

    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: updatedVariants,
    }));
  };

  const handleInputChange = (
    index: number,
    field: string,
    value: string
  ): void => {
    const updatedVariants = [...variant.variants];
    updatedVariants[index] = { ...updatedVariants[index], [field]: value };
    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: updatedVariants,
    }));
  };

  const handleAddProductVariant = () => {
    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: [
        ...prevProduct.variants,
        {
          price: 0,
          dealPrice: "",
          colorCode: "",
          colorName: "",
          images: [],
          watchStyle: "",
          watchSize: "",
          watchConnection: "",
        },
      ],
    }));
  };

  const handleCallBack = () => {
    const updatedProduct = {
      ...variant,
      variants: [...variant.variants],
    };
    console.log(updatedProduct.variants);

    setVariant(updatedProduct);
    onProductUpdate(updatedProduct.variants);
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file: RcFile) => {
      return false;
    },
    onChange: async (info: any, index: number) => {
      const newFileList = info.fileList;
      const base64Files = await Promise.all(
        newFileList.map(async (file: UploadFile) => {
          const base64 = await convertToBase64(file.originFileObj as RcFile);
          return {
            ...file,
            base64,
          };
        })
      );
      handleFileChange(index, base64Files);
    },
  };

  const convertToBase64 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (index: number, fileList: UploadFile[]) => {
    const updatedVariants = [...variant.variants];
    updatedVariants[index].images = fileList;
    setVariant((prevProduct) => ({
      ...prevProduct,
      variants: updatedVariants,
    }));
  };

  return (
    <div className="product-contain-box">
      {variant.variants.map((variant, index) => (
        <div key={index} className="product-box">
          {/* Price */}
          <div>
            {index === 0 && <label className="addbrand-label">M.R.P</label>}
            <Input
              className="product-input"
              value={variant.price}
              onChange={(e) =>
                handleInputChange(index, "price", e.target.value)
              }
            />
          </div>

          {/* dealPrice */}
          <div>
            {index === 0 && (
              <label className="addbrand-label">DealPrice</label>
            )}
            <Input
              className="product-input"
              value={variant.dealPrice}
              onChange={(e) =>
                handleInputChange(index, "dealPrice", e.target.value)
              }
            />
          </div>

          {/* Color */}
          <div>
            {index === 0 && <label className="addbrand-label">Color</label>}
            <div
              onClick={() => {
                const colorInput = document.getElementById(
                  `hiddenColorInput-${index}`
                );
                if (colorInput) colorInput.click();
              }}
              style={{
                backgroundColor: variant.colorCode,
                width: "30px",
                height: "30px",
                cursor: "pointer",
                borderRadius: "50%",
                border: "1px solid #000",
              }}
            />
            <Input
              type="color"
              id={`hiddenColorInput-${index}`}
              value={variant.colorCode}
              onChange={(e) => handleColorChange(e, index)}
              style={{ display: "none" }}
            />
          </div>

          {/* Color Name */}
          <div>
            {index === 0 && <label className="addbrand-label">Color Name</label>}
            <Input value={variant.colorName} readOnly className="product-input" />
          </div>

          {/* Watch Style */}
          <div>
            {index === 0 && <label className="addbrand-label">Watch Style</label>}
            <Input
              className="product-input"
              value={variant.watchStyle}
              onChange={(e) =>
                handleInputChange(index, "watchStyle", e.target.value)
              }
            />
          </div>

          {/* Watch Size */}
          <div>
            {index === 0 && <label className="addbrand-label">Watch Size</label>}
            <Input
              className="product-input"
              value={variant.watchSize}
              onChange={(e) =>
                handleInputChange(index, "watchSize", e.target.value)
              }
            />
          </div>

          {/* Watch Connection */}
          <div>
            {index === 0 && (
              <label className="addbrand-label">Watch Connection</label>
            )}
            <Input
              className="product-input"
              value={variant.watchConnection}
              onChange={(e) =>
                handleInputChange(index, "watchConnection", e.target.value)
              }
            />
          </div>

          {/* Upload Images */}
          <div>
            <StyledDragger
              {...props}
              onChange={(info) => props.onChange(info, index)}
              fileList={variant.images}
              className="dragger-box"
            >
              <UploadOutlined />
            </StyledDragger>
          </div>
        </div>
      ))}

      <div className="text-center">
        <ButtonA className="add-btn-box" onClick={handleAddProductVariant}>
          +
        </ButtonA>
      </div>
      <div className="text-center pt-3">
        <Button onClick={handleCallBack} className="btn-save">
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddWatchVariant;
