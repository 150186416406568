import React from "react";
import { Container } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dealsofsamsung from "./Dealsofsamsung";
import Dealofday from "./Dealofday";
import Hotdealofsmartphone from "./HotDealsofSmartphones";
import Bestofelectronics from "./BestofElectronics";
import Limitedtimedeals from "./LimitedTimeDeals";
import DealofDay from "./Dealofday";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HomeOfferMain = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Home Offers</h2>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Hot Deals of Smart Phones" {...a11yProps(1)} />
                  <Tab label="Best of Electronics" {...a11yProps(2)} />
                  <Tab label="Limited Time Deals" {...a11yProps(3)} />
                  <Tab label="Deal of the Day" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Hotdealofsmartphone />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Bestofelectronics/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Limitedtimedeals/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <DealofDay />
              </CustomTabPanel>
            </Box>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HomeOfferMain;
