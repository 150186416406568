import React from "react";
import { Container } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import HeroBanner from "./HeroBanner";
import Offerbanner from "./Offerbanner";
import Shopbybrand from "./FestivalBanners";
import Popularbrand from "./Popularbrand";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HomeBannerMain = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
          <h2 className="head-txt">Home Banners</h2>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Hero Banner" {...a11yProps(0)} />
                  <Tab label="Offer Banner" {...a11yProps(1)} />
                  <Tab label="Festival Brands" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <HeroBanner />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Offerbanner />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Shopbybrand />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Popularbrand />
              </CustomTabPanel>
            </Box>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HomeBannerMain;
