import React, { useEffect, useState } from "react";
import SideBar from "./Sidebar";
import "../App.css";
import Header from "../Home/Header";
import type { MenuProps } from "antd";
import { Dropdown, Select, Space } from "antd";
import TodayOrder from "../Home/Todayorders";
import Newuser from "./Newuser";
import Salesperformance from "./Salesperformance";
import axios from "axios";
import { API_URL } from "../Api/Config";
import Analytics from "./Analytics";

// Define types for the report data
interface ReportData {
  newOrdersCount: number;
  completedOrdersCount: number;
  canceledOrdersCount: number;
  completedOrderRevenue: number;
  transactionsCount: number;
  revenue: number;
  usersCreated: number;
  replacedOrderCount: number;
  replacedOrderRevenue: number;
}

const Home: React.FC = () => {
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [pastReportData,setPastReportData]=useState<ReportData | null>(null);
  const [dropvalue, setDropvalue] = useState("today");

  const fetchReportData = async () => {
    const token=localStorage.getItem("dashtoken")
    if(dropvalue==="today"){
      const today=await axios.get(`${API_URL}/api/report?type=today`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setReportData(today.data.report)
      const yesterday=await axios.get(`${API_URL}/api/report?type=previousDay`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setPastReportData(yesterday.data.report)
    }
    else if(dropvalue==="thisweek"){
      const today=await axios.get(`${API_URL}/api/report?type=thisWeek`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setReportData(today.data.report)
      const yesterday=await axios.get(`${API_URL}/api/report?type=previousWeek`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setPastReportData(yesterday.data.report)
    }else{
      const today=await axios.get(`${API_URL}/api/report?type=thisMonth`)
      setReportData(today.data.report)
      const yesterday=await axios.get(`${API_URL}/api/report?type=previousMonth`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setPastReportData(yesterday.data.report)
    }
  };
  

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDropvalue(value);
  };

  useEffect(() => {
    fetchReportData();
  }, [dropvalue]);

  if (!reportData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex">
      <section className="sidebar">
        <SideBar />
      </section>
      <section className="home">
        <Header />
        <section className="section-main top-gap">
          <div className="order-div justify-content-between align-items-center">
            <h2 className="head-txt">Order Performance</h2>
            <div>
              <Select
                defaultValue="today"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "today", label: "Today" },
                  { value: "thisweek", label: "This Week" },
                  { value: "thismonth", label: "This Month" },
                ]}
              />
            </div>
          </div>

          <div className="order-row row">
            {[
              {
                title: "Total Orders",
                number: reportData.completedOrdersCount,
                percentage: pastReportData?.completedOrdersCount && pastReportData.completedOrdersCount !== 0
                ? ((reportData.completedOrdersCount - pastReportData.completedOrdersCount) / pastReportData.completedOrdersCount) * 100
                : 100,

                icon: "fi fi-rs-box-open",
                trend: "up",
                description: dropvalue==="today"?"than yesterday":dropvalue==="thisweek"?"than last week":"than last month",
              },
              {
                title: "Total Amount",
                number: reportData.completedOrderRevenue,
                percentage: pastReportData?.completedOrderRevenue && pastReportData.completedOrderRevenue !== 0
                ? ((reportData.completedOrderRevenue - pastReportData.completedOrderRevenue) / pastReportData.completedOrderRevenue) * 100
                : 100,
                icon: "fi fi-rs-clock",
                trend: "down",
                description: dropvalue==="today"?"than yesterday":dropvalue==="thisweek"?"than last week":"than last month",
              },
              {
                title: "Total Replace Orders",
                number: reportData.replacedOrderCount,
                percentage: pastReportData?.replacedOrderCount 
                ? ((reportData.replacedOrderCount - pastReportData.replacedOrderCount) / pastReportData.replacedOrderCount) * 100 
                : 100,
                icon: "fi fi-rs-check-circle",
                trend: "up",
                description: dropvalue==="today"?"than yesterday":dropvalue==="thisweek"?"than last week":"than last month",
              },
              {
                title: "Total Cancel Orders",
                number: reportData.canceledOrdersCount,
                percentage: pastReportData?.canceledOrdersCount 
                ? ((reportData.canceledOrdersCount - pastReportData.canceledOrdersCount) / pastReportData.canceledOrdersCount) * 100 
                : 100,
                icon: "fi fi-rs-ban",
                trend: "down",
                description: dropvalue==="today"?"than yesterday":dropvalue==="thisweek"?"than last week":"than last month",
              },
            ].map((order, index) => (
              <div key={index} className="col-lg-3 p-2">
                <div className="box">
                  <div className="row box-ins-row ">
                    <div className="col-6">
                      <div>
                        <h6 className="box-head">{order.title}</h6>
                        <h3 className="box-no">{order.number}</h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex justify-content-end">
                        <div className="box-icon">
                          <i className={order.icon}></i> 
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div>
                        {/* Change percentage and description color based on trend */}
                        <p
                          className="txt-box"
                          style={{
                            color: order.percentage>0  ? "green" : "red", 
                          }}
                        >
                          {order.percentage.toFixed()}{" "}
                          <i
                            className={`fi fi-rs-arrow-trend-${
                              order.percentage>0 ? "up" : "down"
                            }`}
                          ></i>
                        </p>
                        <p
                          className="txt-box"
                          style={{
                            color:order.percentage>0 ? "green" : "red", 
                          }}
                        >
                          {order.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <TodayOrder />
        {/* <Newuser /> */}
        <Salesperformance />
       <Analytics/>
      </section>
    </div>
  );
};

export default Home;