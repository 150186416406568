import React, { useState } from "react";
import { Input } from "antd";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Api/Config";

const AddNewDelivery = () => {
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    pincode: "",
    standardDeliveryDate: "",
    expressDeliveryDate: "",
  });
  
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Handle input change
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      // Replace with your actual API endpoint
      const response = await axios.post(`${API_URL}/api/delivery-info`, formData);
      console.log("Data saved successfully:", response.data);
      // Optionally, navigate to another page after success
      navigate("/set-estimate-delivery");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <section className="section-main top-gap" style={{ marginLeft: "18%" }}>
      <div className="head-txt d-flex ">
        <div className="icons-div">
          <i className="fi fi-rr-caret-left "></i>
        </div>
        <div>Add Location</div>
      </div>
      <div className="select-combine justify-content-end align-items-end">
        <div className="mt-4 ">
          <Button className="btn-1" onClick={() => navigate("/add-new-delivery")}>
            Add New
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label className="addbrand-label">State*</label>
          <br />
          <Input
            className="brand-input"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">District*</label>
          <br />
          <Input
            className="brand-input"
            name="district"
            value={formData.district}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Pincode (First 4digits)*</label>
          <br />
          <Input
            className="brand-input"
            name="pincode"
            value={formData.pincode}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Standard Delivery</label>
          <br />
          <Input
            className="brand-input"
            name="standardDeliveryDate"
            value={formData.standardDeliveryDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="addbrand-label">Express Delivery*</label>
          <br />
          <Input
            className="brand-input"
            name="expressDeliveryDate"
            value={formData.expressDeliveryDate}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-4">
          <Button
           type="submit" className="btn-1">
            Submit
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddNewDelivery;
