import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import { Link } from "react-router-dom";
import { Switch } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../Api/Config";

interface DataType {
  key: React.Key;
  sno: string;
  position: string;
  image: string;
  link: string;
  active: boolean;
  action: string;
}

const HeroBanner = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // Track selected rows

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/banner/heroBanners`
        );
        const banners: DataType[] = response.data.banners.map(
          (banner: any, index: number) => ({
            key: banner._id,
            sno: (index + 1).toString(),
            position: banner.position,
            image: `${API_URL}/public/images/${banner.image}`,
            link: banner.link,
            active: banner.actionButton,
            action: "",
          })
        );
        setData(banners);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, []);

  // Handle toggling the active status of the banner
  const handleActiveToggle = async (record: DataType) => {
    const updatedActiveStatus = !record.active;

    try {
      // Update the active status on the server
      await axios.patch(`${API_URL}/api/banner/heroBanners/${record.key}`, {
        actionButton: updatedActiveStatus,
      });

      // Update the active status in the local state
      setData(prevData =>
        prevData.map(item =>
          item.key === record.key ? { ...item, active: updatedActiveStatus } : item
        )
      );
    } catch (error) {
      console.error("Error updating banner status:", error);
    }
  };

  // Handle deleting selected banners
  const handleDeleteSelectedBanners = async () => {
    console.log(selectedRowKeys);
    try {
      await Promise.all(
        selectedRowKeys.map(async (key) => {
          await axios.delete(`${API_URL}/api/banner/heroBanners/${key}`);
        })
      );
      // Update the local state to remove deleted banners
      setData(prevData => prevData.filter(item => !selectedRowKeys.includes(item.key)));
      setSelectedRowKeys([]); // Clear selection after deletion
    } catch (error) {
      console.error("Error deleting selected banners:", error);
    }
  };

  const columns: TableColumnsType<DataType> = [
    { title: "S. No", dataIndex: "sno" },
    { title: "Position", dataIndex: "position" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image: string) => (
        <div className="offer-img">
          <img src={image} className="img-fluid" alt="img" />
        </div>
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      render: (link: string) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      ),
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={() => handleActiveToggle(record)}
          size="small"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_,) => (
        <div className="d-flex justify-content-start">
          <Link to="/add-banner/festival" className="links">
            <i className="fi fi-rs-pencil edit"></i>
          </Link>
        </div>
      ),
    },
  ];

  const rowSelection: TableProps<DataType>["rowSelection"] = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys), // Update selectedRowKeys on change
  };

  return (
    <>
      <div className="select-combine">
        <div className="d-flex justify-content-end w-100 gap-3">
          <Link to="/add-banner">
            <Button className="btn-1">Add New</Button>
          </Link>
          <Button
            onClick={handleDeleteSelectedBanners}
            className="btn-1"
            disabled={!selectedRowKeys.length}
          >
            Delete Selected
          </Button>
        </div>
      </div>
      <div className="pt-5">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          className="tableborder"
        />
      </div>
    </>
  );
};

export default HeroBanner;
