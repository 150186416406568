import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../Api/Config";

const label = { inputProps: { "aria-label": "Size switch demo" } };

interface DataType {
  key: React.Key;
  sno: string;
  productName: string;
  productId: string;
  categories: string;
  time: string;
  active: boolean;
  action: string;
  intervalId?: number; // Optional interval ID for countdown timer
}



const DealofDay = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  // Separate function to fetch deals data
  const fetchDealsData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/banner/dealOfTheDay`);
      const deals: DataType[] = response.data.deals.map((deal: any, index: number) => {
        const timeString = deal.time
          ? `${deal.time.hr || 0}h ${deal.time.min || 0}m ${deal.time.sec || 0}s`
          : "0h 0m 0s";

        return {
          key: deal._id,
          sno: (index + 1).toString(),
          categories: deal.categories?.[0] || "-",
          productName: deal.productName || "-",
          productId: deal.productId || "-",
          time: timeString,
          active: deal.actionButton ?? false,
          action: "",
        };
      });

      setData(deals);

      // Initialize countdown for each deal
      deals.forEach((deal, index) => {
        const intervalId = window.setInterval(() => {
          setData((prevData) => {
            const updatedData = [...prevData];
            const currentDeal = updatedData[index];

            if (currentDeal) {
              // Parse and decrement the countdown time
              const [hours, minutes, seconds] = currentDeal.time.split(/h|m|s/).map(Number);
              let newHours = hours;
              let newMinutes = minutes;
              let newSeconds = seconds - 1;

              if (newSeconds < 0) {
                newSeconds = 59;
                newMinutes--;
              }
              if (newMinutes < 0) {
                newMinutes = 59;
                newHours--;
              }
              if (newHours < 0) {
                newHours = 0;
                newMinutes = 0;
                newSeconds = 0;
                clearInterval(currentDeal.intervalId);
              }

              currentDeal.time = `${newHours}h ${newMinutes}m ${newSeconds}s`;
            }

            return updatedData;
          });
        }, 1000); // Update every second

        // Store interva lId for later cleanup
        deals[index].intervalId = intervalId;
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDealsData();

    // Cleanup intervals on component unmount
    return () => {
      data.forEach((deal) => {
        if (deal.intervalId) clearInterval(deal.intervalId);
      });
    };
  }, []);

  const handleActiveToggle = async (record: DataType) => {
    const updatedActiveStatus = !record.active;

    try {
      // Update the active status on the server
      await axios.patch(`https://vnext-api.onrender.com/api/banner/dealOfTheDay/${record.key}`, {
        actionButton: updatedActiveStatus,
      });

      // Update the active status in the local state
      setData(prevData =>
        prevData.map(item =>
          item.key === record.key ? { ...item, active: updatedActiveStatus } : item
        )
      );
    } catch (error) {
      console.error("Error updating banner status:", error);
    }
  };
  const handleAddProduct = () => {
    navigate("/add-product");
  };

  const rowSelection: TableProps<DataType>["rowSelection"] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log("Selected rows:", selectedRows);
    },
  };

  const columns: TableColumnsType<DataType> = [
    { title: "S. No", dataIndex: "sno"},
    { title: "Product Name", dataIndex: "productName" },
    { title: "Product Id", dataIndex: "productId"},
    { title: "Categories", dataIndex: "categories" },
    { title: "Time", dataIndex: "time" },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={() => handleActiveToggle(record)}
          size="small"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div className="d-flex justify-content-start">
          <Link to="/edit-product" className="links">
            <i className="fi fi-rs-pencil edit"></i>
          </Link>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="select-combine justify-content-end">
        <Button className="btn-1" onClick={handleAddProduct} style={{ marginTop: "28px" }}>
          Add New
        </Button>
        <Button className="btn-1" style={{ marginTop: "28px" }}>Delete</Button>
      </div>
      <div className="pt-3">
        <Table
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          loading={loading}
          className="tableborder"
        />
      </div>
    </>
  );
};

export default DealofDay;
