import React, { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { Input } from "antd";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import { createTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { message, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

// Custom styles for the special checkbox
const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-custom": {
            border: "1px dotted black", // Dotted border
            padding: "0", // Remove default padding
            borderRadius: "4px", // Optional: make it rounded
          },
        },
      },
    },
  },
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddBrands = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file as FileType);
    });
    setUploading(true);
    // You can use any AJAX library you like
    fetch("https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        message.success("upload successfully.");
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <>
      <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
        <div className="order-div justify-content-between">
          <h2 className="head-txt">New Brands</h2>
          <div>
            <Button className="btn-1">Delete</Button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div>
              <label className="addbrand-label">Brand</label>
              <br />
              <Input className="brand-input" />
              <br />

              <label className="addbrand-label">Categories</label>
              <br />
              <div className="newbrand-checkbox">
                <Checkbox {...label} size="small" />
                <label className="categories-label">Smart Phone</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Tab</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Laptop</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Watch</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Camera</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Music System</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">MP3 Player </label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">DVD Player</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Refrigerator </label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Washing Machine</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Consumer Appliance</label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Cooking System </label>

                <Checkbox {...label} size="small" />
                <label className="categories-label">Air Conditioner </label>

                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<AddIcon />}
                      checkedIcon={<AddIcon />}
                      className="MuiCheckbox-custom mx-2"
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                        color: "black",
                      }}
                    />
                  }
                  label=""
                />
              </div>

              <label className="addbrand-label">
                Brand Banner{" "}
                <span className="label-2">(Upload in 183*19 px)</span>
              </label>
              <br />

              <div>
                <Upload {...props}>
                  <button className="upload-div">
                    <div>
                      <div>
                        <i className="fi fi-rs-cloud-upload-alt upload"></i>
                      </div>
                      <div>Upload</div>
                    </div>
                  </button>
                </Upload>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
        </div>

        <div className="addnew-btn-combine pb-2">
          <div>
            <Button className="btn-2">Cancel</Button>
          </div>

          <div>
            <Button className="btn-1">Save</Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddBrands;
